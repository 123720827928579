import "../App.css";

import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { useForm } from "react-hook-form";

import { SERVER_HOST } from "../constant";
import {
  VALIDATION_NAME,
  VALIDATION_TEL_NO,
  VALIDATION_CORPORATION_NAME,
  VALIDATION_GENERAL_VALUE,
} from "../constantBiz";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

import AuthLayout from "../components/layout/AuthLayout.js";
import MyText from "../components/MyText.js";
import MySelect from "../components/MySelect.js";
import MyNoteActionFrame from "../components/template/MyNoteActionFrame.js";
import { useCookies } from "react-cookie";

// NoteFuneralメイン
function NoteFuneral(props) {
  // ルーター
  const navigate = useNavigate();
  const location = useLocation();
  // 現在のデータ
  const [currentData, setCurrentData] = useState();
  // 編集モード
  const [editMode, setEditMode] = useState(false);
  // メッセージ
  const [message, setMessage] = useState("");
  // モーダル
  const [modal, setModal] = useState(false);
  // バリデーション
  const { handleSubmit, register, formState, setValue, control } = useForm();

  // Cookie
  const [cookies, setCookie, removeCookie] = useCookies();
  // CSRFトークン
  const [csrfToken, setCsrfToken] = useState("");

  // 初期表示
  useEffect(() => {
    if (!location.state) {
      navigate("/");
      return;
    }

    props.getCsrfToken(setCsrfToken);
    getInfo();
  }, []);

  // 情報取得
  const getInfo = () => {
    const auth = getAuth();
    onAuthStateChanged(auth, (userObj) => {
      setModal(true);
      userObj
        .getIdToken()
        .then(function (idToken) {
          (async () => {
            try {
              const response = await fetch(
                `${SERVER_HOST}/api/user/note/funeral`,
                {
                  method: "GET",
                  headers: {
                    "Content-Type": "application/json",
                    "Custom-Fb-Token": idToken,
                  },
                  credentials: "include",
                }
              );

              setModal(false);
              if (response.ok) {
                const data = await response.json();
                for (var key in data) {
                  setValue(key, data[key]);
                }
                setCurrentData(data);
              } else {
                props.errorCommon(response);
              }
            } catch (error) {
              console.log(error);
            }
          })();
        })
        .catch(function (error) {
          // Handle error
        });
    });
  };

  // 登録／更新
  const upsert = (data) => {
    const param = data;

    const auth = getAuth();
    onAuthStateChanged(auth, (userObj) => {
      if (!userObj || !cookies["login"]) {
        return;
      }
      userObj
        .getIdToken()
        .then((idToken) => {
          (async () => {
            try {
              const response = await fetch(
                `${SERVER_HOST}/api/user/note/funeral`,
                {
                  method: "PUT",
                  headers: {
                    "Content-Type": "application/json",
                    "Custom-Fb-Token": idToken,
                    "X-CSRF-Token": csrfToken,
                  },
                  credentials: "include",
                  body: JSON.stringify(param),
                }
              );

              if (response.ok) {
                setMessage("更新しました。");
                setEditMode(false);
                getInfo();
                window.scrollTo(0, 0);
              } else {
                props.errorCommon(response);
              }
            } catch (error) {
              console.log(error);
            }
          })();
        })
        .catch((error) => {
          // Handle error
        });
    });
  };

  // 描画
  return (
    <AuthLayout
      modal={modal}
      onCloseModal={setModal}
      snackMessage={message}
      onCloseSnack={() => setMessage("")}
      csrfToken={csrfToken}
    >
      <Container maxWidth="sm" component="main" sx={{ p: 12 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sx={{ mt: 30 }}>
            <Typography
              variant="h6"
              sx={{
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              葬祭について
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ mt: 10 }}>
            <MyText
              register={register("funeral_plan_hope", {
                ...VALIDATION_NAME,
              })}
              control={control}
              editMode={editMode}
              label="葬祭プラン希望"
              placeholder="葬祭プランの希望を入力"
              helpTip="小さく家族のみで実施したい、など"
              error={formState.errors.funeral_plan_hope}
            ></MyText>
          </Grid>
          <Grid item xs={12} sx={{ mt: 10 }}>
            <MyText
              register={register("funeral_home_name", {
                ...VALIDATION_CORPORATION_NAME,
              })}
              control={control}
              editMode={editMode}
              label="実施葬儀社"
              placeholder="葬儀社を入力"
              error={formState.errors.funeral_home_name}
            ></MyText>
          </Grid>
          <Grid item xs={12} sx={{ mt: 10 }}>
            <MyText
              register={register("funeral_home_tel_no", {
                ...VALIDATION_TEL_NO,
              })}
              control={control}
              editMode={editMode}
              label="実施葬儀社電話番号"
              placeholder="実施葬儀社電話番号を入力"
              error={formState.errors.funeral_home_tel_no}
            ></MyText>
          </Grid>
          <Grid item xs={12} sx={{ mt: 10 }}>
            <MyText
              register={register("funeral_option", {
                VALIDATION_GENERAL_VALUE,
              })}
              control={control}
              editMode={editMode}
              label="オプション内容"
              placeholder="オプション内容を入力"
              error={formState.errors.funeral_option}
            ></MyText>
          </Grid>
          <Grid item xs={12} sx={{ mt: 10 }}>
            <MySelect
              register={register("follow_of_relatives", {})}
              control={control}
              editMode={editMode}
              label="親族意向に従うか"
              placeholder="親族意向に従うかを選択"
              targetList={{
                1: "親族意向に従う",
                2: "どちらでも良い",
                3: "親族意向に従わない",
              }}
              error={formState.errors.follow_of_relatives}
            ></MySelect>
          </Grid>
        </Grid>
        <MyNoteActionFrame
          editMode={editMode}
          setEditMode={() => {
            if (editMode) {
              for (var key in currentData) {
                setValue(key, currentData[key]);
              }
            }
            setEditMode(!editMode);
          }}
          onClickExec={handleSubmit(upsert)}
          onClickBackIcon={() =>
            navigate("/note", { state: { personId: "", note: true } })
          }
        ></MyNoteActionFrame>
      </Container>
    </AuthLayout>
  );
}

export default NoteFuneral;
