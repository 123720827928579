import React from "react";

import Button from "@mui/material/Button";

// MyButtonExecメイン
function MyButtonExec(props) {
  return (
    <React.Fragment>
      <Button
        size="large"
        variant="contained"
        color="primary"
        fullWidth
        disableElevation
        onClick={props.onClick}
        className="effect-btn"
        sx={{ borderRadius: 2 }}
      >
        {props.label}
      </Button>
    </React.Fragment>
  );
}

export default MyButtonExec;
