import "../App.css";

import React, { useState, useEffect } from "react";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { useNavigate, useLocation } from "react-router-dom";
import { SERVER_HOST } from "../constant";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";

import AuthLayout from "../components/layout/AuthLayout.js";
import MyFamilyFrame from "../components/template/MyFamilyFrame";
import MyButtonExec from "../components/MyButtonExec.js";
import MyTab from "../components/MyTab";
import MyTabPanel from "../components/MyTabPanel";
import MyContactList from "../components/template/MyContactList";
import { useCookies } from "react-cookie";

// Homeメイン
function Home(props) {
  // 取得情報
  const [userInfo, setUserInfo] = useState({});

  // 選択タブ
  const [selectTab, setSelectTab] = useState(0);
  // モーダル
  const [modal, setModal] = useState(false);
  // Snack
  const [message, setMessage] = useState("");

  // ルーター
  const navigate = useNavigate();
  const location = useLocation();
  // Cookie
  const [cookies, setCookie, removeCookie] = useCookies();
  // CSRFトークン
  const [csrfToken, setCsrfToken] = useState("");

  const getInfo = (basePersonId) => {
    const auth = getAuth();
    onAuthStateChanged(auth, (userObj) => {
      if (!userObj || !cookies["login"]) {
        return;
      }
      setModal(true);
      userObj
        .getIdToken()
        .then(function (idToken) {
          (async () => {
            try {
              const response = await fetch(
                `${SERVER_HOST}/api/user/person?base_person_id=${basePersonId}`,
                {
                  method: "GET",
                  headers: {
                    "Content-Type": "application/json",
                    "Custom-Fb-Token": idToken,
                  },
                  credentials: "include",
                }
              );

              if (response.ok) {
                const data = await response.json();
                setModal(false);
                setUserInfo(data);
              } else {
                props.errorCommon(response);
              }
            } catch (error) {
              console.log(error);
            }
          })();
        })
        .catch(function (error) {
          // Handle error
        });
    });
  };
  // 初期表示
  useEffect(() => {
    props.getCsrfToken(setCsrfToken);
    if (location.state?.snackMessage) {
      setMessage(location.state.snackMessage);
    }
    getInfo(location.state ? location.state.personId : "");
  }, []);

  // タブ変更処理
  const changeTab = (event, newValue) => {
    setSelectTab(newValue);
  };

  // 描画
  return (
    <React.Fragment>
      <AuthLayout
        modal={modal}
        onCloseModal={setModal}
        snackMessage={message}
        onCloseSnack={() => setMessage("")}
        barClickTarget="note"
        csrfToken={csrfToken}
      >
        <Container maxWidth="sm" component="main" sx={{ p: 10 }}>
          {(() => {
            if (userInfo.base) {
              return (
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  sx={{ pt: 25 }}
                >
                  <Grid item xs={12} sx={{ mt: 6, px: 2 }}>
                    <MyFamilyFrame
                      base={userInfo.base}
                      father={userInfo.father}
                      mother={userInfo.mother}
                      partner={userInfo.partner}
                      brothers={userInfo.brothers}
                      children={userInfo.children}
                      isUser={
                        userInfo.user_person_id === userInfo.base.person_id
                      }
                      switchTarget={getInfo}
                      toNote={() => {
                        navigate("/note", {
                          state: { personId: userInfo.base.person_id },
                        });
                      }}
                      onClickEditIcon={() => {
                        navigate("/person", {
                          state: {
                            personId: userInfo.base.person_id,
                            refMode: true,
                          },
                        });
                      }}
                    ></MyFamilyFrame>
                  </Grid>
                  <Grid item xs={12} sx={{ mt: 10, px: 2, width: "100%" }}>
                    <MyButtonExec
                      label="連絡先を登録する"
                      onClick={() => {
                        navigate("/person", {
                          state: {
                            personId: userInfo.base.person_id,
                            personName: userInfo.base.person_name,
                          },
                        });
                      }}
                    ></MyButtonExec>
                  </Grid>
                  <Grid item xs={12} sx={{ mt: 10, px: 2, width: "100%" }}>
                    <Typography variant="h4" sx={{ fontWeight: "bold" }}>
                      Family
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sx={{ mt: 10, px: 2, width: "100%" }}>
                    <MyTab
                      selectTab={selectTab}
                      onChange={changeTab}
                      targetList={[
                        { label: `親戚 （${userInfo.relatives.length}）` },
                        { label: `友達 （${userInfo.friends.length}）` },
                      ]}
                    ></MyTab>
                  </Grid>
                  <Grid item xs={12} sx={{ mt: 10, px: 2, width: "100%" }}>
                    <MyTabPanel value={selectTab} index={0}>
                      <MyContactList
                        targetList={userInfo.relatives}
                        onClick={getInfo}
                      ></MyContactList>
                    </MyTabPanel>
                    <MyTabPanel value={selectTab} index={1}>
                      <MyContactList
                        targetList={userInfo.friends}
                        onClick={getInfo}
                      ></MyContactList>
                    </MyTabPanel>
                  </Grid>
                </Grid>
              );
            }
          })()}
        </Container>
      </AuthLayout>
    </React.Fragment>
  );
}

export default Home;
