import "../../App.css";

import React from "react";
import dayjs from "dayjs";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import Fab from "@mui/material/Fab";
import CardMedia from "@mui/material/CardMedia";
import Pagination from "@mui/material/Pagination";
import Zoom from "@mui/material/Zoom";
import AddIcon from "@mui/icons-material/Add";

// MyHistoriesListメイン
function MyHistoriesList(props) {
  // 描画
  return (
    <React.Fragment>
      <Box>
        <List
          sx={{
            width: "100%",
            bgcolor: "background.paper",
          }}
        >
          {props.targetList.map((data, index) => {
            var setYear = "";
            if (index === 0) {
              setYear = dayjs(data.event_date).format("YYYY");
            } else if (
              dayjs(data.event_date).format("YYYY") !==
              dayjs(props.targetList[index - 1].event_date).format("YYYY")
            ) {
              setYear = dayjs(data.event_date).format("YYYY");
            }

            return (
              <React.Fragment>
                {(() => {
                  if (setYear) {
                    return (
                      <ListItem
                        alignItems="flex-start"
                        sx={{ height: 50, mb: 4 }}
                      >
                        <Typography
                          variant="h4"
                          sx={{ fontWeight: "bold", ml: -8 }}
                        >
                          {setYear}
                        </Typography>
                      </ListItem>
                    );
                  }
                })()}
                <ListItem
                  alignItems="flex-start"
                  sx={{ height: 90 }}
                  onClick={() => {
                    props.onClickHistory(data.history_id);
                  }}
                >
                  <Grid container spacing={10}>
                    <Grid item xs={3}>
                      <CardMedia
                        component="img"
                        image={`${data.picture_url}`}
                        sx={{ width: 90, ml: -8 }}
                        alt="nodata"
                      />
                    </Grid>
                    <Grid item xs={9}>
                      <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                        {dayjs(data.event_date).format("YYYY年M月D日")}
                      </Typography>
                      <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                        {data.title}
                      </Typography>
                      <Typography
                        sx={{
                          pt: 2,
                          overflow: "hidden",
                          display: "-webkit-box",
                          WebkitBoxOrient: "vertical",
                          WebkitLineClamp: 3,
                          lineHeight: "0.9rem",
                        }}
                        variant="caption"
                      >
                        {data.content}
                      </Typography>
                    </Grid>
                  </Grid>
                </ListItem>
                <Divider sx={{ pt: 10 }} />
              </React.Fragment>
            );
          })}
        </List>
        <Pagination
          page={props.page}
          onChange={(event, value) => {
            props.onChangePage(value);
          }}
          size="small"
          count={props.pageCount}
          sx={{ mt: 4, display: "flex", justifyContent: "center" }}
        />
        <Box sx={{ height: 40 }}></Box>
        <Zoom in={true}>
          <Fab
            size="large"
            sx={{
              position: "fixed",
              bottom: 20,
              right: 20,
            }}
            color="primary"
            onClick={props.onClickAddIcon}
          >
            <AddIcon />
          </Fab>
        </Zoom>
      </Box>
    </React.Fragment>
  );
}

export default MyHistoriesList;
