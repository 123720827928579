import "../App.css";

import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import dayjs from "dayjs";

import { SERVER_HOST } from "../constant";
import {
  VALIDATION_REQUIRED,
  VALIDATION_HISTORY_TITLE,
  VALIDATION_HISTORY_CONTENT,
} from "../constantBiz";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import Zoom from "@mui/material/Zoom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import AuthLayout from "../components/layout/AuthLayout.js";
import MyText from "../components/MyText.js";
import MyTextMulti from "../components/MyTextMulti.js";
import MySelectMulti from "../components/MySelectMulti.js";
import MyDateSelect from "../components/MyDateSelect.js";
import MyButtonExec from "../components/MyButtonExec.js";
import MyPictureSelect from "../components/MyPictureSelect.js";
import { useCookies } from "react-cookie";

// Historyメイン
function History(props) {
  // モーダル
  const [modal, setModal] = useState(false);
  // バリデーション
  const { handleSubmit, register, formState, setValue, control } = useForm();

  // 日付
  const [eventDate, setEventDate] = useState(dayjs());
  // 写真パス
  const [temporaryPath, setTemporaryPath] = useState("");
  const [sourceTemporaryPath, setSourceTemporaryPath] = useState("");
  const [temporaryUrl, setTemporaryUrl] = useState("");

  //
  const [companionList, setCompanionList] = useState({});

  // ルーター
  const navigate = useNavigate();
  const location = useLocation();
  // Cookie
  const [cookies, setCookie, removeCookie] = useCookies();
  // CSRFトークン
  const [csrfToken, setCsrfToken] = useState("");

  // 初期表示
  useEffect(() => {
    if (!location.state) {
      navigate("/");
      return;
    }

    props.getCsrfToken(setCsrfToken);
    // 更新の場合
    if (location.state.history?.historyId) {
      getInfo();
    }
    // 同伴者リスト取得
    getCompanionList();
  }, []);

  // 情報取得
  const getInfo = () => {
    const auth = getAuth();
    onAuthStateChanged(auth, (userObj) => {
      if (!userObj || !cookies["login"]) {
        return;
      }

      setModal(true);
      userObj
        .getIdToken()
        .then(function (idToken) {
          (async () => {
            try {
              const response = await fetch(
                `${SERVER_HOST}/api/user/history/${location.state.history.historyId}?person_id=${location.state.personId}`,
                {
                  method: "GET",
                  headers: {
                    "Content-Type": "application/json",
                    "Custom-Fb-Token": idToken,
                  },
                  credentials: "include",
                }
              );

              setModal(false);
              if (response.ok) {
                const data = await response.json();
                // フォームに設定
                for (var key in data) {
                  setValue(key, data[key]);
                }

                setValue(
                  "companion",
                  data.companion_list.map((data) => {
                    return data.person_id;
                  })
                );

                setEventDate(dayjs(data.birth_day));
                setTemporaryPath(data.picture_path);
                setSourceTemporaryPath(data.picture_path);
                setTemporaryUrl(data.picture_url);
              } else {
                props.errorCommon(response);
              }
            } catch (error) {
              console.log(error);
            }
          })();
        })
        .catch(function (error) {
          // Handle error
        });
    });
  };

  // 同伴者リスト取得
  const getCompanionList = () => {
    const auth = getAuth();
    onAuthStateChanged(auth, (userObj) => {
      if (!userObj || !cookies["login"]) {
        return;
      }
      setModal(true);
      userObj
        .getIdToken()
        .then(function (idToken) {
          (async () => {
            try {
              const response = await fetch(
                `${SERVER_HOST}/api/user/person/namelist?person_id=${location.state.personId}`,
                {
                  method: "GET",
                  headers: {
                    "Content-Type": "application/json",
                    "Custom-Fb-Token": idToken,
                  },
                  credentials: "include",
                }
              );

              setModal(false);
              if (response.ok) {
                // フォームに設定
                const data = await response.json();
                const wkArray = {};
                for (var row of data.name_list) {
                  wkArray[row.person_id] = row.person_name;
                }
                setCompanionList(wkArray);
              } else {
                props.errorCommon(response);
              }
            } catch (error) {
              console.log(error);
            }
          })();
        })
        .catch(function (error) {
          // Handle error
        });
    });
  };

  // 画像ファイル選択
  const selectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }

    const formData = new FormData();
    formData.append("file", e.target.files[0]);

    const auth = getAuth();
    onAuthStateChanged(auth, (userObj) => {
      if (!userObj || !cookies["login"]) {
        return;
      }
      userObj
        .getIdToken()
        .then((idToken) => {
          (async () => {
            try {
              const response = await fetch(
                `${SERVER_HOST}/api/picture/landscape`,
                {
                  method: "POST",
                  headers: {
                    "Custom-Fb-Token": idToken,
                    "X-CSRF-Token": csrfToken,
                  },
                  credentials: "include",
                  body: formData,
                }
              );

              if (response.ok) {
                // エラーメッセージを表示
                const data = await response.json();
                setTemporaryPath(data.temporary_path);
                setTemporaryUrl(`${data.url}`);
              } else {
                props.errorCommon(response);
              }
            } catch (error) {
              console.log(error);
            }
          })();
        })
        .catch((error) => {
          // Handle error
        });
    });
  };

  // 登録
  const insert = (data) => {
    const param = {
      ...data,
      person_id: location.state.personId,
      event_date: eventDate.format("YYYY-MM-DD"),
      next_picture_path: temporaryPath,
    };

    const auth = getAuth();
    onAuthStateChanged(auth, (userObj) => {
      if (!userObj || !cookies["login"]) {
        return;
      }
      userObj
        .getIdToken()
        .then((idToken) => {
          (async () => {
            try {
              const response = await fetch(`${SERVER_HOST}/api/user/history`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  "Custom-Fb-Token": idToken,
                  "X-CSRF-Token": csrfToken,
                },
                credentials: "include",
                body: JSON.stringify(param),
              });

              if (response.ok) {
                // Note画面に遷移
                navigate("/note", {
                  state: { ...location.state, snackMessage: "登録しました。" },
                });
              } else {
                props.errorCommon(response);
              }
            } catch (error) {
              console.log(error);
            }
          })();
        })
        .catch((error) => {
          // Handle error
        });
    });
  };

  // 更新
  const update = (data) => {
    const param = {
      ...data,
      person_id: location.state.personId,
      event_date: eventDate.format("YYYY-MM-DD"),
      next_picture_path:
        sourceTemporaryPath !== temporaryPath ? temporaryPath : "",
    };

    const auth = getAuth();
    onAuthStateChanged(auth, (userObj) => {
      if (!userObj || !cookies["login"]) {
        return;
      }
      userObj
        .getIdToken()
        .then((idToken) => {
          (async () => {
            try {
              const response = await fetch(
                `${SERVER_HOST}/api/user/history/${location.state.history.historyId}`,
                {
                  method: "PUT",
                  headers: {
                    "Content-Type": "application/json",
                    "Custom-Fb-Token": idToken,
                    "X-CSRF-Token": csrfToken,
                  },
                  credentials: "include",
                  body: JSON.stringify(param),
                }
              );

              if (response.ok) {
                // Note画面に遷移
                navigate("/note", {
                  state: {
                    ...location.state,
                    snackMessage: "更新しました。",
                  },
                });
              } else {
                props.errorCommon(response);
              }
            } catch (error) {
              console.log(error);
            }
          })();
        })
        .catch((error) => {
          // Handle error
        });
    });
  };

  // 描画
  return (
    <React.Fragment>
      <AuthLayout modal={modal} onCloseModal={setModal} csrfToken={csrfToken}>
        <Container maxWidth="sm" component="main" sx={{ p: 10 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sx={{ mt: 20 }}>
              <MyDateSelect
                editMode={true}
                required
                label="日付"
                placeholder="日付を入力"
                value={eventDate}
                onChange={setEventDate}
              ></MyDateSelect>
            </Grid>
            <Grid item xs={12} sx={{ mt: 10 }}>
              <MyText
                register={register("title", {
                  ...VALIDATION_REQUIRED,
                  ...VALIDATION_HISTORY_TITLE,
                })}
                control={control}
                editMode={true}
                required
                label="タイトル"
                placeholder="タイトルを入力"
                error={formState.errors.title}
              ></MyText>
            </Grid>
            <Grid item xs={12} sx={{ mt: 10 }}>
              <MySelectMulti
                register={register("companion", {})}
                control={control}
                editMode={true}
                label="同行者"
                placeholder="同行者を選択"
                targetList={companionList}
                error={formState.errors.companion}
              ></MySelectMulti>
            </Grid>
            <Grid item xs={12} sx={{ mt: 10 }}>
              <MyPictureSelect
                editMode={true}
                label="写真"
                size="70%"
                url={temporaryUrl}
                onChange={selectFile}
              ></MyPictureSelect>
            </Grid>
            <Grid item xs={12} sx={{ mt: 10 }}>
              <MyTextMulti
                register={register("content", {
                  ...VALIDATION_REQUIRED,
                  ...VALIDATION_HISTORY_CONTENT,
                })}
                editMode={true}
                required
                label="内容"
                placeholder="内容を入力"
                minRows={3}
                maxRows={20}
                error={formState.errors.content}
              ></MyTextMulti>
            </Grid>
            <Grid item xs={12} sx={{ mt: 20, px: 6, width: "100%" }}>
              <MyButtonExec
                label={
                  location.state.history?.historyId ? "変更する" : "登録する"
                }
                onClick={
                  location.state.history?.historyId
                    ? handleSubmit(update)
                    : handleSubmit(insert)
                }
              ></MyButtonExec>
            </Grid>
            <Box height={150}></Box>
            <Zoom in={true}>
              <Fab
                size="small"
                sx={{
                  position: "fixed",
                  bottom: 20,
                  left: 20,
                }}
                color="primary"
                onClick={() => {
                  navigate("/note", {
                    state: location.state,
                  });
                }}
              >
                <ArrowBackIcon />
              </Fab>
            </Zoom>
          </Grid>
        </Container>
      </AuthLayout>
    </React.Fragment>
  );
}

export default History;
