import "../App.css";

import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { SERVER_HOST, HISTORY_PAGE_LIMIT } from "../constant";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";

import AuthLayout from "../components/layout/AuthLayout.js";
import MyNoteMeFrame from "../components/template/MyNoteMeFrame";
import MyTab from "../components/MyTab";
import MyTabPanel from "../components/MyTabPanel";
import MyNoteList from "../components/template/MyNoteList";
import MyHistoriesList from "../components/template/MyHistoriesList";
import MyHistoryDialog from "../components/template/MyHistoryDialog";
import { useCookies } from "react-cookie";

// Homeメイン
function Note(props) {
  // 人物ID
  const [personId, setPersonId] = useState("");

  // 本人情報
  const [person, setPerson] = useState({
    person_name: "",
    birth_day: null,
    tel_no: "",
    thumbnail_path: "",
  });
  // ユーザ本人か
  const [isUser, setIsUser] = useState(false);
  // History情報リスト(１ページ当たり)
  const [histories, setHistories] = useState([]);
  // History総件数
  const [historiesCount, setHistoriesCount] = useState(0);
  // History現在ページ
  const [historyPage, setHistoryPage] = useState(1);

  // 選択タブ
  const [selectTab, setSelectTab] = useState(0);
  // 選択中のHistory情報
  const [targetHistory, setTargetHistory] = useState({});
  // Historyダイアログ
  const [dialogHistory, setDialogHistory] = useState(false);

  // モーダル
  const [modal, setModal] = useState(false);
  // Snack
  const [message, setMessage] = useState("");

  // ルーター
  const navigate = useNavigate();
  const location = useLocation();
  // Cookie
  const [cookies, setCookie, removeCookie] = useCookies();
  // CSRFトークン
  const [csrfToken, setCsrfToken] = useState("");

  // 初期表示
  useEffect(() => {
    if (!location.state) {
      navigate("/");
      return;
    }

    props.getCsrfToken(setCsrfToken);
    setPersonId(location.state.personId);
    if (location.state?.snackMessage) {
      setMessage(location.state.snackMessage);
    }

    getInfo(location.state.personId);
    if (location.state.history) {
      setHistoryPage(location.state.history.page);
      getHistoryInfo(location.state.personId, location.state.history.page);
    } else {
      getHistoryInfo(location.state.personId, 1);
    }
    if (location.state.note) {
      setSelectTab(1);
    }
  }, []);

  // 情報取得
  const getInfo = (basePersonId) => {
    const auth = getAuth();
    onAuthStateChanged(auth, (userObj) => {
      if (!userObj || !cookies["login"]) {
        return;
      }
      setModal(true);
      userObj
        .getIdToken()
        .then(function (idToken) {
          (async () => {
            try {
              const response = await fetch(
                `${SERVER_HOST}/api/user/person?base_person_id=${basePersonId}`,
                {
                  method: "GET",
                  headers: {
                    "Content-Type": "application/json",
                    "Custom-Fb-Token": idToken,
                  },
                  credentials: "include",
                }
              );
              setModal(false);

              if (response.ok) {
                // 本人情報を取得
                const data = await response.json();
                setPerson(data.base);
                setIsUser(data.user_person_id === data.base.person_id);
              } else {
                props.errorCommon(response);
              }
            } catch (error) {
              console.log(error);
            }
          })();
        })
        .catch(function (error) {
          // Handle error
        });
    });
  };

  // History情報リスト取得
  const getHistoryInfo = (basePersonId, targetPage) => {
    const auth = getAuth();
    onAuthStateChanged(auth, (userObj) => {
      if (!userObj || !cookies["login"]) {
        return;
      }
      setModal(true);
      userObj
        .getIdToken()
        .then(function (idToken) {
          (async () => {
            try {
              const response = await fetch(
                `${SERVER_HOST}/api/user/histories?person_id=${basePersonId}&page=${targetPage}&limit=${HISTORY_PAGE_LIMIT}`,
                {
                  method: "GET",
                  headers: {
                    "Content-Type": "application/json",
                    "Custom-Fb-Token": idToken,
                  },
                  credentials: "include",
                }
              );

              setModal(false);

              if (response.ok) {
                // 対象ページのHistory情報を取得
                const data = await response.json();
                setHistoryPage(targetPage);
                setHistories(data.histories);
                setHistoriesCount(data.total_count);
              } else {
                props.errorCommon(response);
              }
            } catch (error) {
              console.log(error);
            }
          })();
        })
        .catch(function (error) {
          // Handle error
        });
    });
  };

  // タブ切り替え時
  const changeTab = (event, newValue) => {
    setSelectTab(newValue);
  };

  // ページ選択時
  const selectPage = (targetPage) => {
    if (targetPage === historyPage) {
      return;
    }

    // 対象ページの情報を取得
    getHistoryInfo(location.state.personId, targetPage);
  };

  // 対象History情報選択
  const selectHistory = (historyId) => {
    const auth = getAuth();
    onAuthStateChanged(auth, (userObj) => {
      if (!userObj || !cookies["login"]) {
        return;
      }
      userObj
        .getIdToken()
        .then(function (idToken) {
          (async () => {
            try {
              const response = await fetch(
                `${SERVER_HOST}/api/user/history/${historyId}?person_id=${personId}`,
                {
                  method: "GET",
                  headers: {
                    "Content-Type": "application/json",
                    "Custom-Fb-Token": idToken,
                  },
                  credentials: "include",
                }
              );

              if (response.ok) {
                // 対象ページのHistory情報を取得
                const data = await response.json();
                setTargetHistory(data);
                setDialogHistory(true);
              } else {
                props.errorCommon(response);
              }
            } catch (error) {
              console.log(error);
            }
          })();
        })
        .catch(function (error) {
          // Handle error
        });
    });
  };

  // History情報削除
  const deleteHistory = (historyId) => {
    const auth = getAuth();
    onAuthStateChanged(auth, (userObj) => {
      if (!userObj || !cookies["login"]) {
        return;
      }
      userObj
        .getIdToken()
        .then(function (idToken) {
          (async () => {
            try {
              const response = await fetch(
                `${SERVER_HOST}/api/user/history/${historyId}`,
                {
                  method: "DELETE",
                  headers: {
                    "Content-Type": "application/json",
                    "Custom-Fb-Token": idToken,
                    "X-CSRF-Token": csrfToken,
                  },
                  credentials: "include",
                }
              );

              if (response.ok) {
                setDialogHistory(false);
                setMessage("削除しました。");
                getHistoryInfo(location.state.personId, historyPage);
              } else {
                props.errorCommon(response);
              }
            } catch (error) {
              console.log(error);
            }
          })();
        })
        .catch(function (error) {
          // Handle error
        });
    });
  };

  // 描画
  return (
    <React.Fragment>
      <AuthLayout
        modal={modal}
        onCloseModal={setModal}
        snackMessage={message}
        onCloseSnack={() => setMessage("")}
        csrfToken={csrfToken}
      >
        <Container maxWidth="sm" component="main" sx={{ p: 10 }}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            sx={{ pt: 20 }}
          >
            <Grid item xs={12}>
              <MyNoteMeFrame base={person} isUser={isUser}></MyNoteMeFrame>
            </Grid>
            {(() => {
              if (person.person_name) {
                return (
                  <React.Fragment>
                    <Grid item xs={12} sx={{ mt: 10, px: 4, width: "100%" }}>
                      <MyTab
                        selectTab={selectTab}
                        onChange={changeTab}
                        targetList={
                          isUser
                            ? [{ label: "HISTORY" }, { label: "NOTE" }]
                            : [{ label: "HISTORY" }]
                        }
                      ></MyTab>
                    </Grid>
                    <Grid item xs={12} sx={{ mt: 10, px: 4, width: "100%" }}>
                      <MyTabPanel value={selectTab} index={0}>
                        <MyHistoriesList
                          basePersonId={personId}
                          pageCount={Math.ceil(historiesCount / 4)}
                          page={historyPage}
                          targetList={histories}
                          onChangePage={selectPage}
                          onClickHistory={selectHistory}
                          onClickAddIcon={() =>
                            navigate("/history", {
                              state: {
                                personId: personId,
                                history: {
                                  historyId: "",
                                  page: historyPage,
                                },
                              },
                            })
                          }
                        ></MyHistoriesList>
                      </MyTabPanel>
                      <MyTabPanel value={selectTab} index={1}>
                        <MyNoteList
                          targetList={[
                            { title: "ご本人について", path: "/note/me" },
                            { title: "生活について", path: "/note/life" },
                            {
                              title: "金融機関について",
                              path: "/note/finance",
                            },
                            { title: "負債について", path: "/note/debt" },
                            { title: "死後対応について", path: "/note/after" },
                            { title: "葬祭について", path: "/note/funeral" },
                            { title: "遺品について", path: "/note/possession" },
                          ]}
                        ></MyNoteList>
                      </MyTabPanel>
                    </Grid>
                  </React.Fragment>
                );
              }
            })()}
          </Grid>
          <Dialog
            open={dialogHistory}
            onClose={() => {
              setDialogHistory(false);
            }}
            scroll="paper"
            sx={{ mx: -6 }}
          >
            <MyHistoryDialog
              target={targetHistory}
              basePersonId={personId}
              page={historyPage}
              onClickEditIcon={() => {
                setDialogHistory(false);
                navigate("/history", {
                  state: {
                    personId: personId,
                    history: {
                      historyId: targetHistory.history_id,
                      page: historyPage,
                    },
                  },
                });
              }}
              deleteHistory={deleteHistory}
            ></MyHistoryDialog>
          </Dialog>
        </Container>
      </AuthLayout>
    </React.Fragment>
  );
}

export default Note;
