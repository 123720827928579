import React from "react";
import dayjs from "dayjs";
import ja from "dayjs/locale/ja";

import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { Stack } from "@mui/material";

// MyDateSelectメイン
function MyDateSelect(props) {
  return (
    <React.Fragment>
      <Typography sx={{ fontWeight: "bold" }}>{props.label}</Typography>
      {(() => {
        if (props.editMode) {
          return (
            <React.Fragment>
              <Typography variant="caption">
                {props.required ? "※必須" : "※任意"}
              </Typography>
              <Stack sx={{ mb: 2 }}></Stack>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale={ja}
              >
                <DesktopDatePicker
                  value={props.value}
                  slotProps={{
                    textField: {
                      variant: "standard",
                      placeholder: props.placeholder,
                    },
                    toolbar: {
                      hidden: true,
                    },
                    field: { clearable: !props.required, onClear: () => {} },
                  }}
                  closeOnSelect={false}
                  sx={{ width: 260, pt: 2 }}
                  format="YYYY年M月D日(ddd)"
                  inputProps={{
                    readOnly: !props.editMode,
                  }}
                  onChange={(newValue) => props.onChange(newValue)}
                />
              </LocalizationProvider>
            </React.Fragment>
          );
        } else {
          return (
            <React.Fragment>
              <TextField
                value={(() => {
                  if (props.value) {
                    return props.value.locale("ja").format("YYYY年M月D日(ddd)");
                  } else {
                    return "";
                  }
                })()}
                variant="standard"
                inputProps={{ readOnly: true }}
                sx={{ pt: 2 }}
              />
            </React.Fragment>
          );
        }
      })()}
    </React.Fragment>
  );
}

export default MyDateSelect;
