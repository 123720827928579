import "../../App.css";

import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Zoom from "@mui/material/Zoom";
import Fab from "@mui/material/Fab";
import EditIcon from "@mui/icons-material/Edit";
import EditOffIcon from "@mui/icons-material/EditOff";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import MyButtonExec from "../../components/MyButtonExec.js";

// MyNoteActionFrameメイン
function MyNoteActionFrame(props) {
  // 確認ダイアログ
  const [dialog, setDialog] = useState(false);

  // 描画
  return (
    <React.Fragment>
      {(() => {
        if (props.editMode) {
          return (
            <React.Fragment>
              <Grid item xs={12} sx={{ mt: 30, px: 6, width: "100%" }}>
                <MyButtonExec
                  label="登録する"
                  onClick={() => props.onClickExec()}
                ></MyButtonExec>
              </Grid>
              <Box height={120}></Box>
              <Zoom in={true}>
                <Fab
                  size="small"
                  sx={{
                    position: "fixed",
                    bottom: 20,
                    right: 20,
                  }}
                  color="primary"
                  onClick={() => setDialog(true)}
                >
                  <EditOffIcon />
                </Fab>
              </Zoom>
            </React.Fragment>
          );
        } else {
          if (!props.message) {
            return (
              <React.Fragment>
                <Box height={120}></Box>
                <Zoom in={true}>
                  <Fab
                    size="small"
                    sx={{
                      position: "fixed",
                      bottom: 20,
                      right: 20,
                    }}
                    color="primary"
                    onClick={props.setEditMode}
                  >
                    <EditIcon />
                  </Fab>
                </Zoom>
                <Zoom in={true}>
                  <Fab
                    size="small"
                    sx={{
                      position: "fixed",
                      bottom: 20,
                      left: 20,
                    }}
                    color="primary"
                    onClick={props.onClickBackIcon}
                  >
                    <ArrowBackIcon />
                  </Fab>
                </Zoom>
              </React.Fragment>
            );
          }
        }
      })()}
      <Dialog open={dialog} scroll="paper" sx={{ mx: -6 }}>
        <DialogContent>編集中の内容を破棄しますか？</DialogContent>
        <DialogActions>
          <Button variant="text" onClick={() => setDialog(false)}>
            キャンセル
          </Button>
          <Button
            variant="text"
            onClick={() => {
              setDialog(false);
              props.setEditMode();
            }}
          >
            ＯＫ
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default MyNoteActionFrame;
