import "../App.css";

import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { useForm } from "react-hook-form";

import { SERVER_HOST } from "../constant";
import {
  VALIDATION_NAME,
  VALIDATION_TEL_NO,
  VALIDATION_CORPORATION_NAME,
  VALIDATION_GENERAL_VALUE,
  VALIDATION_ID_NUMBER,
} from "../constantBiz";

import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

import AuthLayout from "../components/layout/AuthLayout.js";
import MyText from "../components/MyText.js";
import MyNoteActionFrame from "../components/template/MyNoteActionFrame.js";
import { useCookies } from "react-cookie";

// NoteLifeメイン
function NoteLife(props) {
  // ルーター
  const navigate = useNavigate();
  const location = useLocation();
  // 編集モード
  const [editMode, setEditMode] = useState(false);
  // 現在のデータ
  const [currentData, setCurrentData] = useState();
  // メッセージ
  const [message, setMessage] = useState("");
  // モーダル
  const [modal, setModal] = useState(false);
  // バリデーション
  const { handleSubmit, register, formState, setValue, control } = useForm();

  // Cookie
  const [cookies, setCookie, removeCookie] = useCookies();
  // CSRFトークン
  const [csrfToken, setCsrfToken] = useState("");

  // 初期表示
  useEffect(() => {
    if (!location.state) {
      navigate("/");
      return;
    }

    props.getCsrfToken(setCsrfToken);
    getInfo();
  }, []);

  // 情報取得
  const getInfo = () => {
    const auth = getAuth();
    onAuthStateChanged(auth, (userObj) => {
      if (!userObj || !cookies["login"]) {
        return;
      }
      setModal(true);
      userObj
        .getIdToken()
        .then(function (idToken) {
          (async () => {
            try {
              const response = await fetch(
                `${SERVER_HOST}/api/user/note/life`,
                {
                  method: "GET",
                  headers: {
                    "Content-Type": "application/json",
                    "Custom-Fb-Token": idToken,
                  },
                  credentials: "include",
                }
              );

              setModal(false);
              if (response.ok) {
                const data = await response.json();
                for (var key in data) {
                  setValue(key, data[key]);
                }
                setCurrentData(data);
              } else {
                props.errorCommon(response);
              }
            } catch (error) {
              console.log(error);
            }
          })();
        })
        .catch(function (error) {
          // Handle error
        });
    });
  };

  // 登録／更新
  const upsert = (data) => {
    const param = data;

    const auth = getAuth();
    onAuthStateChanged(auth, (userObj) => {
      if (!userObj || !cookies["login"]) {
        return;
      }
      userObj
        .getIdToken()
        .then((idToken) => {
          (async () => {
            try {
              const response = await fetch(
                `${SERVER_HOST}/api/user/note/life`,
                {
                  method: "PUT",
                  headers: {
                    "Content-Type": "application/json",
                    "Custom-Fb-Token": idToken,
                    "X-CSRF-Token": csrfToken,
                  },
                  credentials: "include",
                  body: JSON.stringify(param),
                }
              );

              if (response.ok) {
                setMessage("更新しました。");
                setEditMode(false);
                getInfo();
                window.scrollTo(0, 0);
              } else {
                props.errorCommon(response);
              }
            } catch (error) {
              console.log(error);
            }
          })();
        })
        .catch((error) => {
          // Handle error
        });
    });
  };

  // 描画
  return (
    <AuthLayout
      modal={modal}
      onCloseModal={setModal}
      snackMessage={message}
      onCloseSnack={() => setMessage("")}
      csrfToken={csrfToken}
    >
      <Container maxWidth="sm" component="main" sx={{ p: 12 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sx={{ mt: 30 }}>
            <Typography
              variant="h6"
              sx={{
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              生活について
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ mt: 10 }}>
            <MyText
              register={register("basic_penision_number", {
                ...VALIDATION_ID_NUMBER,
              })}
              control={control}
              editMode={editMode}
              label="基礎年金番号"
              placeholder="基礎年金番号を入力"
              error={formState.errors.basic_penision_number}
            ></MyText>
          </Grid>
          <Grid item xs={12} sx={{ mt: 10 }}>
            <MyText
              register={register("company_penision_number", {
                ...VALIDATION_ID_NUMBER,
              })}
              control={control}
              editMode={editMode}
              label="企業年金番号"
              placeholder="企業年金番号を入力"
              error={formState.errors.company_penision_number}
            ></MyText>
          </Grid>
          <Grid item xs={12} sx={{ mt: 10 }}>
            <MyText
              register={register("penision_fund_number", {
                ...VALIDATION_ID_NUMBER,
              })}
              control={control}
              editMode={editMode}
              label="国民年金基金番号"
              placeholder="国民年金基金番号を入力"
              error={formState.errors.company_penision_number}
            ></MyText>
          </Grid>
          <Grid item xs={12} sx={{ mt: 10 }}>
            <MyText
              register={register("health_insurance_number", {
                ...VALIDATION_ID_NUMBER,
              })}
              control={control}
              editMode={editMode}
              label="国民健康保険番号"
              placeholder="国民健康保険番号を入力"
              error={formState.errors.health_insurance_number}
            ></MyText>
          </Grid>
          <Grid item xs={12} sx={{ mt: 10 }}>
            <MyText
              register={register("health_association_number", {
                ...VALIDATION_ID_NUMBER,
              })}
              control={control}
              editMode={editMode}
              label="健保組合・協会保険番号"
              placeholder="健保組合・協会保険番号を入力"
              error={formState.errors.health_association_number}
            ></MyText>
          </Grid>
          <Grid item xs={12} sx={{ mt: 10 }}>
            <MyText
              register={register("elderly_medical_insurance_number", {
                ...VALIDATION_ID_NUMBER,
              })}
              control={control}
              editMode={editMode}
              label="後期高齢者医療保険番号"
              placeholder="後期高齢者医療保険番号を入力"
              error={formState.errors.elderly_medical_insurance_number}
            ></MyText>
          </Grid>
          <Grid item xs={12} sx={{ mt: 10 }}>
            <MyText
              register={register("care_insurance_number", {
                ...VALIDATION_ID_NUMBER,
              })}
              control={control}
              editMode={editMode}
              label="介護保険番号"
              placeholder="介護保険番号を入力"
              error={formState.errors.care_insurance_number}
            ></MyText>
          </Grid>
          <Grid item xs={12} sx={{ mt: 10 }}>
            <MyText
              register={register("other_insurance", {
                ...VALIDATION_GENERAL_VALUE,
              })}
              control={control}
              editMode={editMode}
              label="その他保険内容"
              placeholder="その他保険内容を入力"
              error={formState.errors.other_insurance}
            ></MyText>
          </Grid>
          <Grid item xs={12} sx={{ mt: 10 }}>
            <MyText
              register={register("electric_company_name", {
                ...VALIDATION_CORPORATION_NAME,
              })}
              control={control}
              editMode={editMode}
              label="電気契約会社名"
              placeholder="電気契約会社名を入力"
              error={formState.errors.electric_company_name}
            ></MyText>
          </Grid>
          <Grid item xs={12} sx={{ mt: 10 }}>
            <MyText
              register={register("electric_company_tel_no", {
                ...VALIDATION_TEL_NO,
              })}
              control={control}
              editMode={editMode}
              label="電気契約会社電話番号"
              placeholder="電気契約電話番号を入力"
              error={formState.errors.electric_company_tel_no}
            ></MyText>
          </Grid>
          <Grid item xs={12} sx={{ mt: 10 }}>
            <MyText
              register={register("electric_company_account", {
                ...VALIDATION_ID_NUMBER,
              })}
              control={control}
              editMode={editMode}
              label="電気お客様番号"
              placeholder="電気お客様番号を入力"
              error={formState.errors.electric_company_account}
            ></MyText>
          </Grid>
          <Grid item xs={12} sx={{ mt: 10 }}>
            <MyText
              register={register("gas_company_name", {
                ...VALIDATION_CORPORATION_NAME,
              })}
              control={control}
              editMode={editMode}
              label="ガス契約会社名"
              placeholder="ガス契約会社名を入力"
              error={formState.errors.gas_company_name}
            ></MyText>
          </Grid>
          <Grid item xs={12} sx={{ mt: 10 }}>
            <MyText
              register={register("gas_company_tel_no", {
                ...VALIDATION_TEL_NO,
              })}
              control={control}
              editMode={editMode}
              label="ガス連絡先電話番号"
              placeholder="ガス連絡先電話番号を入力"
              error={formState.errors.gas_company_tel_no}
            ></MyText>
          </Grid>
          <Grid item xs={12} sx={{ mt: 10 }}>
            <MyText
              register={register("gas_company_account", {
                ...VALIDATION_ID_NUMBER,
              })}
              control={control}
              editMode={editMode}
              label="ガスお客様番号"
              placeholder="ガスお客様番号を入力"
              error={formState.errors.gas_company_account}
            ></MyText>
          </Grid>
          <Grid item xs={12} sx={{ mt: 10 }}>
            <MyText
              register={register("water_company_name", {
                ...VALIDATION_CORPORATION_NAME,
              })}
              control={control}
              editMode={editMode}
              label="水道契約会社名"
              placeholder="水道契約会社名を入力"
              error={formState.errors.gas_company_account}
            ></MyText>
          </Grid>
          <Grid item xs={12} sx={{ mt: 10 }}>
            <MyText
              register={register("water_company_tel_no", {
                ...VALIDATION_TEL_NO,
              })}
              control={control}
              editMode={editMode}
              label="水道連絡先電話番号"
              placeholder="水道連絡先電話番号を入力"
              error={formState.errors.water_company_tel_no}
            ></MyText>
          </Grid>
          <Grid item xs={12} sx={{ mt: 10 }}>
            <MyText
              register={register("water_company_account", {
                ...VALIDATION_ID_NUMBER,
              })}
              control={control}
              editMode={editMode}
              label="水道お客様番号"
              placeholder="水道お客様番号を入力"
              error={formState.errors.water_company_account}
            ></MyText>
          </Grid>
          <Grid item xs={12} sx={{ mt: 10 }}>
            <MyText
              register={register("telecommunication_company_name", {
                ...VALIDATION_CORPORATION_NAME,
              })}
              control={control}
              editMode={editMode}
              label="電話契約会社名"
              placeholder="電話契約会社名を入力"
              error={formState.errors.telecommunication_company_name}
            ></MyText>
          </Grid>
          <Grid item xs={12} sx={{ mt: 10 }}>
            <MyText
              register={register("telecommunication_company_tel_no", {
                ...VALIDATION_TEL_NO,
              })}
              control={control}
              editMode={editMode}
              label="電話問い合わせ番号"
              placeholder="電話問い合わせ番号を入力"
              error={formState.errors.telecommunication_company_tel_no}
            ></MyText>
          </Grid>
          <Grid item xs={12} sx={{ mt: 10 }}>
            <MyText
              register={register("mobile_carries_company_name", {
                ...VALIDATION_CORPORATION_NAME,
              })}
              control={control}
              editMode={editMode}
              label="携帯契約会社名"
              placeholder="携帯電話契約会社名を入力"
              error={formState.errors.mobile_carries_company_name}
            ></MyText>
          </Grid>
          <Grid item xs={12} sx={{ mt: 10 }}>
            <MyText
              register={register("mobile_carries_company_tel_no", {
                ...VALIDATION_TEL_NO,
              })}
              control={control}
              editMode={editMode}
              label="携帯電話契約者名"
              placeholder="携帯電話契約者名を入力"
              error={formState.errors.mobile_carries_company_tel_no}
            ></MyText>
          </Grid>
          <Grid item xs={12} sx={{ mt: 10 }}>
            <MyText
              register={register("newspaper_company_name", {
                ...VALIDATION_CORPORATION_NAME,
              })}
              control={control}
              editMode={editMode}
              label="新聞取引先"
              placeholder="新聞取引先を入力"
              error={formState.errors.newspaper_company_name}
            ></MyText>
          </Grid>
          <Grid item xs={12} sx={{ mt: 10 }}>
            <MyText
              register={register("nhk_account", {
                ...VALIDATION_ID_NUMBER,
              })}
              control={control}
              editMode={editMode}
              label="NHKお客様番号"
              placeholder="NHKお客様番号を入力"
              error={formState.errors.nhk_account}
            ></MyText>
          </Grid>
          <Grid item xs={12} sx={{ mt: 10 }}>
            <MyText
              register={register("internet_company_name", {
                ...VALIDATION_CORPORATION_NAME,
              })}
              control={control}
              editMode={editMode}
              label="インターネット契約会社名"
              placeholder="インターネット契約会社名を入力"
              error={formState.errors.internet_company_name}
            ></MyText>
          </Grid>
          <Grid item xs={12} sx={{ mt: 10 }}>
            <MyText
              register={register("internet_company_account_name", {
                ...VALIDATION_NAME,
              })}
              control={control}
              editMode={editMode}
              label="インターネット契約者名"
              placeholder="インターネット契約者名を入力"
              error={formState.errors.internet_company_account_name}
            ></MyText>
          </Grid>
          <Grid item xs={12} sx={{ mt: 10 }}>
            <MyText
              register={register("internet_company_account_number", {
                ...VALIDATION_ID_NUMBER,
              })}
              control={control}
              editMode={editMode}
              label="インターネット顧客番号"
              placeholder="インターネット顧客番号を入力"
              error={formState.errors.internet_company_account_number}
            ></MyText>
          </Grid>
          <Grid item xs={12} sx={{ mt: 10 }}>
            <MyText
              register={register("internet_subscription", {
                ...VALIDATION_GENERAL_VALUE,
              })}
              control={control}
              editMode={editMode}
              label="インターネットサブスクサービス利用状況"
              placeholder="サブスクサービス利用状況を入力"
              helpTip="Amazon PrimeVideo、Spotify、など"
              error={formState.errors.internet_subscription}
            ></MyText>
          </Grid>
          <Grid item xs={12} sx={{ mt: 10 }}>
            <MyText
              register={register("other_service", {
                ...VALIDATION_GENERAL_VALUE,
              })}
              control={control}
              editMode={editMode}
              label="その他退会が必要なサービス"
              placeholder="サービス名を入力"
              helpTip="ヤクルト、牛乳屋さん、Netflix、など"
              error={formState.errors.other_service}
            ></MyText>
          </Grid>
        </Grid>
        <MyNoteActionFrame
          editMode={editMode}
          setEditMode={() => {
            if (editMode) {
              for (var key in currentData) {
                setValue(key, currentData[key]);
              }
            }
            setEditMode(!editMode);
          }}
          onClickExec={handleSubmit(upsert)}
          onClickBackIcon={() =>
            navigate("/note", { state: { personId: "", note: true } })
          }
        ></MyNoteActionFrame>
      </Container>
    </AuthLayout>
  );
}

export default NoteLife;
