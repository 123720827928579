import React, { useState, useEffect } from "react";
import { getAuth, signOut, onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { SERVER_HOST } from "../../constant";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ListItemIcon from "@mui/material/ListItemIcon";
import Stack from "@mui/material/Stack";
import Modal from "@mui/material/Modal";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import Zoom from "@mui/material/Zoom";
import Logout from "@mui/icons-material/Logout";
import FamilyRestroomIcon from "@mui/icons-material/FamilyRestroom";
import SummarizeIcon from "@mui/icons-material/Summarize";
import SubjectIcon from "@mui/icons-material/Subject";
import { useCookies } from "react-cookie";

// AuthLayoutメイン
function AuthLayout({
  modal,
  onCloseModal,
  snackMessage,
  onCloseSnack,
  children,
  barClickTarget,
  csrfToken,
}) {
  // メニューアンカー
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  // ルーター
  const navigate = useNavigate();
  // Cookie
  const [cookies, setCookie, removeCookie] = useCookies();

  // 初期表示
  useEffect(() => {
    console.log("あああ");
    // 未認証の場合、ログイン画面に遷移
    const auth = getAuth();
    onAuthStateChanged(auth, (userObj) => {
      if (!userObj || !cookies["login"]) {
        console.log("未認証によるログイン遷移");
        removeCookie("login");
        navigate("/login");
        return;
      }
    });
  }, []);

  // ログアウト
  const logout = () => {
    (async () => {
      try {
        await fetch(`${SERVER_HOST}/api/auth/logout`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": csrfToken,
          },
          credentials: "include",
        });

        const auth = getAuth();
        removeCookie("login");
        window.location.href = "/";
        signOut(auth)
          .then(() => {
            return;
          })
          .catch((err) => {
            console.log(err.message);
          });
      } catch (error) {
        console.log(error);
      }
    })();
  };

  // 描画
  return (
    <React.Fragment>
      <AppBar
        position="fixed"
        color="secondary"
        elevation={0}
        sx={{ border: "none" }}
      >
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ ml: 2 }}
            onClick={(e) => setAnchorEl(e.currentTarget)}
          >
            <MenuIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={() => setAnchorEl(null)}
            MenuListProps={{
              "aria-labelledby": "long-button",
            }}
          >
            <MenuList>
              <MenuItem
                onClose={() => setAnchorEl(null)}
                onClick={() => navigate("/", { state: { personId: "" } })}
              >
                <ListItemIcon>
                  <FamilyRestroomIcon fontSize="small" />
                </ListItemIcon>
                家系図
              </MenuItem>
              <MenuItem
                onClose={() => setAnchorEl(null)}
                onClick={() => navigate("/note", { state: { personId: "" } })}
              >
                <ListItemIcon>
                  <SummarizeIcon fontSize="small" />
                </ListItemIcon>
                エンディングノート
              </MenuItem>
              <MenuItem onClose={() => setAnchorEl(null)} onClick={logout}>
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                ログアウト
              </MenuItem>
              <Divider sx={{ my: 10 }} />
              <MenuItem
                onClose={() => setAnchorEl(null)}
                onClick={() => {
                  window.open(
                    "https://sites.google.com/view/foiawefdksanfoiesjaofijewoaf/%E3%83%9B%E3%83%BC%E3%83%A0"
                  );
                }}
              >
                <ListItemIcon>
                  <SubjectIcon fontSize="small" />
                </ListItemIcon>
                利用規約
              </MenuItem>
              <MenuItem
                onClose={() => setAnchorEl(null)}
                onClick={() => {
                  window.open(
                    "https://kawarinaform.cloudfree.jp/purapori.html"
                  );
                }}
              >
                <ListItemIcon>
                  <SubjectIcon fontSize="small" />
                </ListItemIcon>
                プライバシーポリシー
              </MenuItem>
            </MenuList>
          </Menu>
          <Grid container justifyContent="center" sx={{ ml: -20 }}>
            <Grid item>
              <Typography
                variant="h6"
                sx={{ fontWeight: "bold", fontFamily: "Poppins" }}
                onClick={() => {
                  if (barClickTarget === "note") {
                    navigate("/note", { state: { personId: "" } });
                  } else {
                    navigate("/", { state: { personId: "" } });
                  }
                }}
              >
                nocos
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {(() => {
        if (!modal) {
          return children;
        }
      })()}

      <Modal open={modal} onClose={() => onCloseModal(!modal)}>
        <Stack
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            p: 4,
          }}
        >
          <CircularProgress />
        </Stack>
      </Modal>
      {(() => {
        if (snackMessage) {
          return (
            <Snackbar
              open={snackMessage !== ""}
              onClose={onCloseSnack}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              TransitionComponent={Zoom}
              message={snackMessage}
              sx={{ bottom: 50 }}
              autoHideDuration={3000}
            />
          );
        }
      })()}
    </React.Fragment>
  );
}

export default AuthLayout;
