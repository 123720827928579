import "../../App.css";

import React from "react";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import Divider from "@mui/material/Divider";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Typography } from "@mui/material";

// MyNoteListメイン
function MyNoteList(props) {
  // ルーター
  const navigate = useNavigate();

  // 描画
  return (
    <React.Fragment>
      <Box>
        <List
          sx={{
            width: "100%",
            bgcolor: "background.paper",
          }}
        >
          {props.targetList.map((data, index) => {
            return (
              <React.Fragment>
                <ListItem
                  alignItems="flex-start"
                  disablePadding
                  secondaryAction={
                    <IconButton edge="end" aria-label="delete">
                      <ChevronRightIcon />
                    </IconButton>
                  }
                >
                  <ListItemButton
                    onClick={() => {
                      navigate(data.path, { state: {} });
                    }}
                  >
                    <Typography variant="h6">{data.title}</Typography>
                  </ListItemButton>
                </ListItem>
                <Divider component="li" />
              </React.Fragment>
            );
          })}
        </List>
      </Box>
    </React.Fragment>
  );
}

export default MyNoteList;
