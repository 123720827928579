import React, { useState } from "react";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import Checkbox from "@mui/material/Checkbox";
import { Stack } from "@mui/material";

// LoginStepSecondメイン
function LoginStepSecond(props) {
  // チェック
  const [checked, setChecked] = useState(false);

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Typography
          variant="h5"
          sx={{
            fontWight: "bold",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mb: 6,
          }}
        >
          認証プロセス
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {(() => {
          if (props.message) {
            return (
              <Alert severity="error" sx={{ mb: 4 }}>
                {props.message}
              </Alert>
            );
          }
        })()}
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="携帯番号を入力してください"
          value={props.telNo}
          variant="standard"
          fullWidth
          onChange={(event) => props.onChangeTelNo(event.target.value)}
        />
      </Grid>
      <Grid item xs={12} sx={{ mt: 16 }}>
        <Stack direction="row">
          <Checkbox
            value={checked}
            label=""
            sx={{ ml: -4 }}
            onChange={(event) => setChecked(event.target.checked)}
          ></Checkbox>
          <Typography variant="body2" sx={{ ml: -1, mt: 4 }}>
            <a
              href="https://kawarinaform.cloudfree.jp/purapori.html"
              rel="noreferrer"
              target="_blank"
            >
              利用規約
            </a>
            および
            <a
              href="https://kawarinaform.cloudfree.jp/kiyaku.html"
              rel="noreferrer"
              target="_blank"
            >
              プライバシーポリシー
            </a>
            に同意する
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={12} sx={{ mt: 30, px: 12, width: "100%" }}>
        <Button
          size="large"
          variant="contained"
          color="primary"
          fullWidth
          disableElevation
          disabled={!checked}
          onClick={props.onClick}
          className="effect-btn"
          sx={{ borderRadius: 2 }}
        >
          次へ
        </Button>
      </Grid>
    </React.Fragment>
  );
}

export default LoginStepSecond;
