import "../App.css";

import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { useForm, useFieldArray } from "react-hook-form";
import { SERVER_HOST } from "../constant";
import {
  VALIDATION_REQUIRED,
  VALIDATION_NAME,
  VALIDATION_CORPORATION_NAME,
  VALIDATION_ID_NUMBER,
} from "../constantBiz";

import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionActions from "@mui/material/AccordionActions";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import AuthLayout from "../components/layout/AuthLayout.js";
import MyNoteActionFrame from "../components/template/MyNoteActionFrame.js";
import MyText from "../components/MyText.js";
import MySelect from "../components/MySelect.js";
import { useCookies } from "react-cookie";

// NoteFinanceメイン
function NoteFinance(props) {
  // ルーター
  const navigate = useNavigate();
  const location = useLocation();
  // 編集モード
  const [editMode, setEditMode] = useState(false);
  // 現在のデータ
  const [currentData, setCurrentData] = useState();
  // メッセージ
  const [message, setMessage] = useState("");
  // モーダル
  const [modal, setModal] = useState(false);
  // バリデーション
  const { handleSubmit, register, formState, setValue, control, getValues } =
    useForm();
  // バリデーション（リスト）
  const { fields, append, remove } = useFieldArray({
    name: "finance_list",
    control,
  });

  // Cookie
  const [cookies, setCookie, removeCookie] = useCookies();
  // CSRFトークン
  const [csrfToken, setCsrfToken] = useState("");

  // 初期表示
  useEffect(() => {
    if (!location.state) {
      navigate("/");
      return;
    }

    props.getCsrfToken(setCsrfToken);
    getInfo();
  }, []);

  // 情報取得
  const getInfo = () => {
    const auth = getAuth();
    onAuthStateChanged(auth, (userObj) => {
      if (!userObj || !cookies["login"]) {
        return;
      }
      setModal(true);
      userObj
        .getIdToken()
        .then(function (idToken) {
          (async () => {
            try {
              const response = await fetch(
                `${SERVER_HOST}/api/user/note/finance`,
                {
                  method: "GET",
                  headers: {
                    "Content-Type": "application/json",
                    "Custom-Fb-Token": idToken,
                  },
                  credentials: "include",
                }
              );
              setModal(false);
              if (response.ok) {
                const data = await response.json();
                setValue("finance_list", data.finance_list);
                setCurrentData(data);
              } else {
                props.errorCommon(response);
              }
            } catch (error) {
              console.log(error);
            }
          })();
        })
        .catch(function (error) {
          // Handle error
        });
    });
  };

  // 情報追加
  const addList = () => {
    append({});
  };

  // 情報削除
  const deleteList = (index) => {
    remove(index);
  };

  // 登録／更新
  const upsert = (data) => {
    const param = data;

    const auth = getAuth();
    onAuthStateChanged(auth, (userObj) => {
      userObj
        .getIdToken()
        .then((idToken) => {
          (async () => {
            try {
              const response = await fetch(
                `${SERVER_HOST}/api/user/note/finance`,
                {
                  method: "PUT",
                  headers: {
                    "Content-Type": "application/json",
                    "Custom-Fb-Token": idToken,
                    "X-CSRF-Token": csrfToken,
                  },
                  credentials: "include",
                  body: JSON.stringify(param),
                }
              );

              if (response.ok) {
                setMessage("更新しました。");
                setEditMode(false);
                getInfo();
                window.scrollTo(0, 0);
              } else {
                props.errorCommon(response);
              }
            } catch (error) {
              console.log(error);
            }
          })();
        })
        .catch((error) => {
          // Handle error
        });
    });
  };

  // 描画
  return (
    <AuthLayout
      modal={modal}
      onCloseModal={setModal}
      snackMessage={message}
      onCloseSnack={() => setMessage("")}
      csrfToken={csrfToken}
    >
      <Container maxWidth="sm" component="main" sx={{ p: 12 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sx={{ mt: 30 }}>
            <Typography
              variant="h6"
              sx={{
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              金融機関について
            </Typography>
          </Grid>
          <React.Fragment>
            {(() => {
              if (fields.length === 0 && !editMode) {
                return (
                  <Grid item xs={12} sx={{ mt: 10 }}>
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      右下のボタンから金融機関を追加してください
                    </Typography>
                  </Grid>
                );
              }
            })()}
            {fields.map((field, index) => (
              <>
                <Grid item xs={12} sx={{ mt: 10 }}>
                  <Accordion sx={{ border: "none" }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography
                        variant="body1"
                        sx={{ ml: 2, fontWeight: "bold" }}
                      >
                        {(() => {
                          const title = getValues(
                            `finance_list.${index}.institution_name`
                          );
                          return title ? title : `金融機関 ${index + 1}`;
                        })()}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid cotainer sx={{ p: 4 }}>
                        <Grid item xs={12} sx={{ mt: 4 }}>
                          <MyText
                            key={`institution_name${field.id}`}
                            register={register(
                              `finance_list.${index}.institution_name`,
                              {
                                ...VALIDATION_REQUIRED,
                                ...VALIDATION_CORPORATION_NAME,
                              }
                            )}
                            control={control}
                            editMode={editMode}
                            required
                            label="金融機関名"
                            placeholder="金融機関名を入力"
                            error={
                              formState.errors.finance_list &&
                              formState.errors.finance_list[index]
                                ? formState.errors.finance_list[index]
                                    .institution_name
                                : null
                            }
                          ></MyText>
                        </Grid>
                        <Grid item xs={12} sx={{ mt: 10 }}>
                          <MyText
                            key={`branch_name${field.id}`}
                            register={register(
                              `finance_list.${index}.branch_name`,
                              {
                                ...VALIDATION_CORPORATION_NAME,
                              }
                            )}
                            control={control}
                            editMode={editMode}
                            label="支店名"
                            placeholder="支店名を入力"
                            error={
                              formState.errors.finance_list &&
                              formState.errors.finance_list[index]
                                ? formState.errors.finance_list[index]
                                    .branch_name
                                : null
                            }
                          ></MyText>
                        </Grid>
                        <Grid item xs={12} sx={{ mt: 10 }}>
                          <MySelect
                            key={`account_type${field.id}`}
                            register={register(
                              `finance_list.${index}.account_type`,
                              {}
                            )}
                            control={control}
                            editMode={editMode}
                            label="口座種別"
                            placeholder="口座種別を選択"
                            targetList={{
                              1: "普通",
                              2: "当座",
                            }}
                            error={
                              formState.errors.finance_list &&
                              formState.errors.finance_list[index]
                                ? formState.errors.finance_list[index]
                                    .account_type
                                : null
                            }
                          ></MySelect>
                        </Grid>
                        <Grid item xs={12} sx={{ mt: 10 }}>
                          <MyText
                            key={`account_no${field.id}`}
                            register={register(
                              `finance_list.${index}.account_no`,
                              {
                                ...VALIDATION_ID_NUMBER,
                              }
                            )}
                            control={control}
                            editMode={editMode}
                            label="口座番号"
                            placeholder="口座番号を入力"
                            error={
                              formState.errors.finance_list &&
                              formState.errors.finance_list[index]
                                ? formState.errors.finance_list[index]
                                    .account_no
                                : null
                            }
                          ></MyText>
                        </Grid>
                        <Grid item xs={12} sx={{ mt: 10 }}>
                          <MyText
                            key={`account_name${field.id}`}
                            register={register(
                              `finance_list.${index}.account_name`,
                              {
                                ...VALIDATION_NAME,
                              }
                            )}
                            control={control}
                            editMode={editMode}
                            label="口座名義人"
                            placeholder="口座名義人を入力"
                            error={
                              formState.errors.finance_list &&
                              formState.errors.finance_list[index]
                                ? formState.errors.finance_list[index]
                                    .account_name
                                : null
                            }
                          ></MyText>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                    <AccordionActions>
                      <DeleteIcon
                        size="large"
                        onClick={deleteList}
                      ></DeleteIcon>
                    </AccordionActions>
                  </Accordion>
                </Grid>
              </>
            ))}
          </React.Fragment>
        </Grid>
        {(() => {
          if (editMode) {
            return (
              <Grid
                item
                xs={12}
                sx={{
                  mt: 10,
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button onClick={addList} sx={{ textDecoration: "underline" }}>
                  金融機関を追加する
                </Button>
              </Grid>
            );
          }
        })()}
        <MyNoteActionFrame
          editMode={editMode}
          setEditMode={() => {
            if (editMode) {
              setValue("finance_list", currentData.finance_list);
            }
            setEditMode(!editMode);
          }}
          onClickExec={handleSubmit(upsert)}
          onClickBackIcon={() =>
            navigate("/note", { state: { personId: "", note: true } })
          }
        ></MyNoteActionFrame>
      </Container>
    </AuthLayout>
  );
}

export default NoteFinance;
