import React, { useEffect } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useCookies } from "react-cookie";
// NoAuthLayoutメイン
function NoAuthLayout({ children }) {
  // ルーター
  const navigate = useNavigate();
  // Cookie
  const [cookies, setCookie, removeCookie] = useCookies();

  // 初期表示
  useEffect(() => {
    // 認証済の場合、メイン画面に遷移
    const auth = getAuth();
    onAuthStateChanged(auth, (userObj) => {
      if (userObj && cookies["login"]) {
        console.log("認証によるホーム遷移");
        navigate("/", { state: { personId: "" } });
        return;
      }
    });
  }, []);

  // 描画
  return (
    <React.Fragment>
      <AppBar
        position="fixed"
        color="secondary"
        elevation={0}
        sx={{ border: "none" }}
      >
        <Toolbar>
          <Grid container justifyContent="center">
            <Grid item>
              <Typography
                variant="h6"
                sx={{ fontWeight: "bold", fontFamily: "Poppins" }}
              >
                nocos
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {children}
    </React.Fragment>
  );
}

export default NoAuthLayout;
