import React, { useState } from "react";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Alert from "@mui/material/Alert";

// LoginStepFirstメイン
function LoginStepFirst(props) {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Typography
          variant="h5"
          sx={{
            fontWight: "900",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mb: 6,
          }}
        >
          ログイン
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {(() => {
          if (props.message) {
            return (
              <Alert severity="error" sx={{ mb: 4 }}>
                {props.message}
              </Alert>
            );
          }
        })()}
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="ID"
          value={props.email}
          variant="standard"
          fullWidth
          onChange={(event) => props.onChangeUserId(event.target.value)}
        />
      </Grid>
      <Grid item xs={12} sx={{ mt: 10 }}>
        <FormControl variant="standard" fullWidth>
          <InputLabel htmlFor="standard-adornment-password">
            パスワード
          </InputLabel>
          <Input
            value={props.password}
            id="standard-adornment-password"
            type={showPassword ? "text" : "password"}
            onChange={(event) => props.onChangePassword(event.target.value)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  onMouseDown={(event) => event.preventDefault()}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sx={{ mt: 16 }}>
        <Typography variant="body2">
          パスワードを忘れた方は<a href="/">こちら</a>
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ mt: 30, px: 12, width: "100%" }}>
        <Button
          size="large"
          variant="contained"
          color="primary"
          fullWidth
          disableElevation
          onClick={props.onClick}
          className="effect-btn"
          sx={{ borderRadius: 2 }}
        >
          ログイン
        </Button>
      </Grid>
    </React.Fragment>
  );
}

export default LoginStepFirst;
