import "../App.css";

import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { useForm } from "react-hook-form";

import { SERVER_HOST } from "../constant";
import {
  VALIDATION_REQUIRED,
  VALIDATION_NAME,
  VALIDATION_TEL_NO,
  VALIDATION_EMAIL,
  VALIDATION_ADDRESS,
  VALIDATION_CORPORATION_NAME,
  VALIDATION_GENERAL_VALUE,
} from "../constantBiz";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

import AuthLayout from "../components/layout/AuthLayout.js";
import MyText from "../components/MyText.js";
import MySelect from "../components/MySelect.js";
import MyNoteActionFrame from "../components/template/MyNoteActionFrame.js";
import { useCookies } from "react-cookie";

// NoteAfterメイン
function NoteAfter(props) {
  // ルーター
  const navigate = useNavigate();
  const location = useLocation();
  // 現在のデータ
  const [currentData, setCurrentData] = useState();
  // 編集モード
  const [editMode, setEditMode] = useState(false);
  // メッセージ
  const [message, setMessage] = useState("");
  // モーダル
  const [modal, setModal] = useState(false);
  // バリデーション
  const { handleSubmit, register, formState, setValue, control } = useForm();

  // Cookie
  const [cookies, setCookie, removeCookie] = useCookies();
  // CSRFトークン
  const [csrfToken, setCsrfToken] = useState("");

  // 初期表示
  useEffect(() => {
    if (!location.state) {
      navigate("/");
      return;
    }

    props.getCsrfToken(setCsrfToken);
    getInfo();
  }, []);

  // 情報取得
  const getInfo = () => {
    const auth = getAuth();
    onAuthStateChanged(auth, (userObj) => {
      if (!userObj || !cookies["login"]) {
        return;
      }
      setModal(true);
      userObj
        .getIdToken()
        .then(function (idToken) {
          (async () => {
            try {
              const response = await fetch(
                `${SERVER_HOST}/api/user/note/after`,
                {
                  method: "GET",
                  headers: {
                    "Content-Type": "application/json",
                    "Custom-Fb-Token": idToken,
                  },
                  credentials: "include",
                }
              );

              setModal(false);
              if (response.ok) {
                const data = await response.json();
                for (var key in data) {
                  setValue(key, data[key]);
                }
                setCurrentData(data);
              } else {
                props.errorCommon(response);
              }
            } catch (error) {
              console.log(error);
            }
          })();
        })
        .catch(function (error) {
          // Handle error
        });
    });
  };

  // 登録／更新
  const upsert = (data) => {
    const param = data;

    const auth = getAuth();
    onAuthStateChanged(auth, (userObj) => {
      if (!userObj || !cookies["login"]) {
        return;
      }
      userObj
        .getIdToken()
        .then((idToken) => {
          (async () => {
            try {
              const response = await fetch(
                `${SERVER_HOST}/api/user/note/after`,
                {
                  method: "PUT",
                  headers: {
                    "Content-Type": "application/json",
                    "Custom-Fb-Token": idToken,
                    "X-CSRF-Token": csrfToken,
                  },
                  credentials: "include",
                  body: JSON.stringify(param),
                }
              );

              if (response.ok) {
                setMessage("更新しました。");
                setEditMode(false);
                getInfo();
                window.scrollTo(0, 0);
              } else {
                props.errorCommon(response);
              }
            } catch (error) {
              console.log(error);
            }
          })();
        })
        .catch((error) => {
          // Handle error
        });
    });
  };

  // 描画
  return (
    <AuthLayout
      modal={modal}
      onCloseModal={setModal}
      snackMessage={message}
      onCloseSnack={() => setMessage("")}
      csrfToken={csrfToken}
    >
      <Container maxWidth="sm" component="main" sx={{ p: 12 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sx={{ mt: 30 }}>
            <Typography
              variant="h6"
              sx={{
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              死後対応について
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ mt: 10 }}>
            <MyText
              register={register("interment_place_hope", {
                ...VALIDATION_ADDRESS,
              })}
              control={control}
              editMode={editMode}
              label="納骨場所希望"
              placeholder="納骨場所の希望を入力"
              helpTip="海に散骨してほしい、など"
              error={formState.errors.interment_place_hope}
            ></MyText>
          </Grid>
          <Grid item xs={12} sx={{ mt: 10 }}>
            <MySelect
              register={register("interment_place_exist", {})}
              control={control}
              editMode={editMode}
              label="納骨場所有無"
              placeholder="有無を選択"
              targetList={{
                1: "墓地",
                2: "納骨堂",
                3: "収骨しない",
                4: "その他",
              }}
              error={formState.errors.interment_place_exist}
            ></MySelect>
          </Grid>
          <Grid item xs={12} sx={{ mt: 10 }}>
            <MyText
              register={register("interment_place_name", {
                ...VALIDATION_CORPORATION_NAME,
              })}
              control={control}
              editMode={editMode}
              label="納骨場所名称"
              placeholder="納骨場所名称を入力"
              error={formState.errors.interment_place_name}
            ></MyText>
          </Grid>
          <Grid item xs={12} sx={{ mt: 10 }}>
            <MyText
              register={register("interment_place_tel_no", {
                ...VALIDATION_ADDRESS,
              })}
              control={control}
              editMode={editMode}
              label="納骨場所連絡先"
              placeholder="納骨場所連絡先を入力"
              error={formState.errors.interment_place_tel_no}
            ></MyText>
          </Grid>
          <Grid item xs={12} sx={{ mt: 10 }}>
            <MyText
              register={register("interment_place_address", {
                ...VALIDATION_ADDRESS,
              })}
              control={control}
              editMode={editMode}
              label="納骨場所所在地"
              placeholder="納骨場所所在地を入力"
              error={formState.errors.interment_place_address}
            ></MyText>
          </Grid>
          <Grid item xs={12} sx={{ mt: 10 }}>
            <MySelect
              register={register("interment_scattered_exist", {})}
              control={control}
              editMode={editMode}
              label="散骨有無"
              placeholder="散骨の有無を選択"
              targetList={{
                1: "散骨を希望する",
                2: "散骨を希望しない",
              }}
              error={formState.errors.interment_scattered_exist}
            ></MySelect>
          </Grid>
          <Grid item xs={12} sx={{ mt: 10 }}>
            <MyText
              register={register("interment_scattered_requester", {
                ...VALIDATION_NAME,
              })}
              control={control}
              editMode={editMode}
              label="散骨依頼者"
              placeholder="散骨依頼者を入力"
              error={formState.errors.interment_scattered_requester}
            ></MyText>
          </Grid>
          <Grid item xs={12} sx={{ mt: 10 }}>
            <MyText
              register={register("interment_scattered_place_tel_no", {
                ...VALIDATION_TEL_NO,
              })}
              control={control}
              editMode={editMode}
              label="散骨先電話番号"
              placeholder="散骨先電話番号を入力"
              error={formState.errors.interment_scattered_place_tel_no}
            ></MyText>
          </Grid>
          <Grid item xs={12} sx={{ mt: 10 }}>
            <MyText
              register={register("interment_scattered_place_address", {
                ...VALIDATION_ADDRESS,
              })}
              control={control}
              editMode={editMode}
              label="散骨先所在地"
              placeholder="散骨先所在地を入力"
              error={formState.errors.interment_scattered_place_address}
            ></MyText>
          </Grid>
          <Grid item xs={12} sx={{ mt: 10 }}>
            <MySelect
              register={register("interment_treatment_timing", {
                ...VALIDATION_REQUIRED,
              })}
              control={control}
              editMode={editMode}
              required
              label="お骨の処遇時期"
              placeholder="お骨の処遇時期を選択"
              targetList={{
                1: "火葬後なるはや",
                2: "四十九日後",
                9: "その他",
              }}
              error={formState.errors.interment_treatment_timing}
            ></MySelect>
          </Grid>
          <Grid item xs={12} sx={{ mt: 10 }}>
            <MyText
              register={register("surplus_assets_destination_name", {
                ...VALIDATION_ADDRESS,
              })}
              control={control}
              editMode={editMode}
              label="余剰資産提供先"
              placeholder="余剰資産提供先を入力"
              error={formState.errors.surplus_assets_destination_name}
            ></MyText>
          </Grid>
          <Grid item xs={12} sx={{ mt: 10 }}>
            <MyText
              register={register("surplus_assets_destination_address", {
                ...VALIDATION_ADDRESS,
              })}
              control={control}
              editMode={editMode}
              label="余剰資産提供先住所"
              placeholder="余剰資産提供先住所を入力"
              error={formState.errors.surplus_assets_destination_address}
            ></MyText>
          </Grid>
          <Grid item xs={12} sx={{ mt: 10 }}>
            <MyText
              register={register("surplus_assets_destination_tel_no", {
                ...VALIDATION_TEL_NO,
              })}
              control={control}
              editMode={editMode}
              label="余剰資産提供先電話番号"
              placeholder="余剰資産提供先電話番号を入力"
              error={formState.errors.surplus_assets_destination_tel_no}
            ></MyText>
          </Grid>
          <Grid item xs={12} sx={{ mt: 10 }}>
            <MyText
              register={register("surplus_assets_destination_email", {
                ...VALIDATION_EMAIL,
              })}
              control={control}
              editMode={editMode}
              label="余剰資産提供先メールアドレス"
              placeholder="余剰資産提供先メールアドレスを入力"
              error={formState.errors.surplus_assets_destination_email}
            ></MyText>
          </Grid>
          <Grid item xs={12} sx={{ mt: 10 }}>
            <MySelect
              register={register("pet_request_exist", {})}
              control={control}
              editMode={editMode}
              label="ペット依頼先有無"
              placeholder="ペット依頼先有無を選択"
              targetList={{
                1: "依頼先が存在する",
                2: "依頼先が存在しない",
              }}
              error={formState.errors.pet_request_exist}
            ></MySelect>
          </Grid>
          <Grid item xs={12} sx={{ mt: 10 }}>
            <MyText
              register={register("pet_request", {
                ...VALIDATION_GENERAL_VALUE,
              })}
              control={control}
              editMode={editMode}
              label="ペット依頼意向"
              placeholder="意向を入力"
              error={formState.errors.pet_request}
            ></MyText>
          </Grid>
          <Grid item xs={12} sx={{ mt: 10 }}>
            <MyText
              register={register("pet_name", {
                ...VALIDATION_NAME,
              })}
              control={control}
              editMode={editMode}
              label="ペット名前"
              placeholder="名前を入力"
              error={formState.errors.pet_name}
            ></MyText>
          </Grid>
          <Grid item xs={12} sx={{ mt: 10 }}>
            <MyText
              register={register("pet_kind", {
                ...VALIDATION_GENERAL_VALUE,
              })}
              control={control}
              editMode={editMode}
              label="ペット種別"
              placeholder="種別を入力"
              error={formState.errors.pet_kind}
            ></MyText>
          </Grid>
          <Grid item xs={12} sx={{ mt: 10 }}>
            <MyText
              register={register("pet_gender", {
                ...VALIDATION_NAME,
              })}
              control={control}
              editMode={editMode}
              label="ペット性別"
              placeholder="性別を入力"
              error={formState.errors.pet_gender}
            ></MyText>
          </Grid>
          <Grid item xs={12} sx={{ mt: 10 }}>
            <MyText
              register={register("pet_birthday", {
                ...VALIDATION_NAME,
              })}
              control={control}
              editMode={editMode}
              label="ペット生年月日"
              placeholder="生年月日を入力"
              error={formState.errors.pet_birthday}
            ></MyText>
          </Grid>
          <Grid item xs={12} sx={{ mt: 10 }}>
            <MySelect
              register={register("pet_health_status")}
              control={control}
              editMode={editMode}
              label="ペット健康状態"
              placeholder="健康状態を選択"
              targetList={{
                1: "健康的",
                2: "治療中",
              }}
              error={formState.errors.pet_health_status}
            ></MySelect>
          </Grid>
          <Grid item xs={12} sx={{ mt: 10 }}>
            <MySelect
              register={register("pet_has_pedigree")}
              control={control}
              editMode={editMode}
              required
              label="ペット血統有無"
              placeholder="血統有無を選択"
              targetList={{
                1: "血統あり",
                2: "血統なし",
              }}
              error={formState.errors.pet_has_pedigree}
            ></MySelect>
          </Grid>
          <Grid item xs={12} sx={{ mt: 10 }}>
            <MySelect
              register={register("pet_contraceptive_surgery")}
              control={control}
              editMode={editMode}
              required
              label="ペット避妊手術有無"
              placeholder="ペット避妊手術有無を選択"
              targetList={{
                1: "避妊手術済み",
                2: "避妊手術はしていない",
              }}
              error={formState.errors.pet_contraceptive_surgery}
            ></MySelect>
          </Grid>
          <Grid item xs={12} sx={{ mt: 10 }}>
            <MyText
              register={register("pet_family_hospital_name", {
                ...VALIDATION_CORPORATION_NAME,
              })}
              control={control}
              editMode={editMode}
              label="かかりつけ病院名"
              placeholder="かかりつけ病院名を入力"
              error={formState.errors.pet_family_hospital_name}
            ></MyText>
          </Grid>
          <Grid item xs={12} sx={{ mt: 10 }}>
            <MyText
              register={register("pet_family_hospital_tel_no", {
                ...VALIDATION_TEL_NO,
              })}
              control={control}
              editMode={editMode}
              label="かかりつけ病院連絡先"
              placeholder="かかりつけ病院連絡先を入力"
              error={formState.errors.pet_family_hospital_tel_no}
            ></MyText>
          </Grid>
        </Grid>
        <MyNoteActionFrame
          editMode={editMode}
          setEditMode={() => {
            if (editMode) {
              for (var key in currentData) {
                setValue(key, currentData[key]);
              }
            }
            setEditMode(!editMode);
          }}
          onClickExec={handleSubmit(upsert)}
          onClickBackIcon={() =>
            navigate("/note", { state: { personId: "", note: true } })
          }
        ></MyNoteActionFrame>
      </Container>
    </AuthLayout>
  );
}

export default NoteAfter;
