import React, { useState, useEffect } from "react";

import { Routes, Route } from "react-router-dom";
import Login from "./routes/Login";
import Home from "./routes/Home";
import Note from "./routes/Note";
import Person from "./routes/Person";
import History from "./routes/History";
import NoteMe from "./routes/NoteMe";
import NoteLife from "./routes/NoteLife";
import NoteFinance from "./routes/NoteFinance";
import NoteDebt from "./routes/NoteDebt";
import NoteAfter from "./routes/NoteAfter";
import NoteFuneral from "./routes/NoteFuneral";
import NotePossession from "./routes/NotePossession";

import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { myTheme, SERVER_HOST } from "./constant";
import { useNavigate, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";

import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";

import { CookiesProvider } from "react-cookie";

function App() {
  // ルーター
  const navigate = useNavigate();
  const location = useLocation();
  // Cookie
  const [cookies, setCookie, removeCookie] = useCookies();

  const getCsrfToken = (setMethod) => {
    (async () => {
      try {
        const response = await fetch(`${SERVER_HOST}/api/auth/csrftoken`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        });

        if (response.ok) {
          const data = await response.json();
          setMethod(data.csrf_token);
          console.log(data.csrf_token);
        } else {
          console.log(response);
        }
      } catch (error) {
        console.log(error);
      }
    })(setMethod);
  };

  const errorCommon = (response) => {
    if (response.status === 401) {
      removeCookie("login");

      const auth = getAuth();
      if (auth) {
        signOut(auth)
          .then(() => {
            navigate("/login");
            return;
          })
          .catch((err) => {
            console.log(err.message);
          });
      }
    } else if (response === 403) {
      window.location.href = "/";
    } else {
      //
    }
    console.log(response);
  };

  return (
    <React.Fragment>
      <ThemeProvider theme={myTheme}>
        <CssBaseline />
        <CookiesProvider>
          <Routes>
            <Route
              path="/"
              element={
                <Home getCsrfToken={getCsrfToken} errorCommon={errorCommon} />
              }
            />
            <Route
              path="/note"
              element={
                <Note getCsrfToken={getCsrfToken} errorCommon={errorCommon} />
              }
            />
            <Route
              path="/login"
              element={<Login getCsrfToken={getCsrfToken} />}
            />
            <Route
              path="/person"
              element={
                <Person getCsrfToken={getCsrfToken} errorCommon={errorCommon} />
              }
            />
            <Route
              path="/history"
              element={
                <History
                  getCsrfToken={getCsrfToken}
                  errorCommon={errorCommon}
                />
              }
            />
            <Route
              path="/note/me"
              element={
                <NoteMe getCsrfToken={getCsrfToken} errorCommon={errorCommon} />
              }
            />
            <Route
              path="/note/life"
              element={
                <NoteLife
                  getCsrfToken={getCsrfToken}
                  errorCommon={errorCommon}
                />
              }
            />
            <Route
              path="/note/finance"
              element={
                <NoteFinance
                  getCsrfToken={getCsrfToken}
                  errorCommon={errorCommon}
                />
              }
            />
            <Route
              path="/note/debt"
              element={
                <NoteDebt
                  getCsrfToken={getCsrfToken}
                  errorCommon={errorCommon}
                />
              }
            />
            <Route
              path="/note/after"
              element={
                <NoteAfter
                  getCsrfToken={getCsrfToken}
                  errorCommon={errorCommon}
                />
              }
            />
            <Route
              path="/note/funeral"
              element={
                <NoteFuneral
                  getCsrfToken={getCsrfToken}
                  errorCommon={errorCommon}
                />
              }
            />
            <Route
              path="/note/possession"
              element={
                <NotePossession
                  getCsrfToken={getCsrfToken}
                  errorCommon={errorCommon}
                />
              }
            />
            <Route
              path="/*"
              element={
                <Home getCsrfToken={getCsrfToken} errorCommon={errorCommon} />
              }
            />
          </Routes>
        </CookiesProvider>
      </ThemeProvider>
    </React.Fragment>
  );
}

export default App;
