import "../App.css";

import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import dayjs from "dayjs";
import ja from "dayjs/locale/ja";
import { SERVER_HOST } from "../constant";
import {
  VALIDATION_REQUIRED,
  VALIDATION_NAME,
  VALIDATION_TEL_NO,
} from "../constantBiz";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import Zoom from "@mui/material/Zoom";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import EditIcon from "@mui/icons-material/Edit";
import EditOffIcon from "@mui/icons-material/EditOff";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Alert from "@mui/material/Alert";

import AuthLayout from "../components/layout/AuthLayout.js";
import MyText from "../components/MyText.js";
import MySelect from "../components/MySelect.js";
import MyDateSelect from "../components/MyDateSelect.js";
import MyButtonExec from "../components/MyButtonExec.js";
import MyPictureSelect from "../components/MyPictureSelect.js";
import { useCookies } from "react-cookie";

// Personメイン
function Person(props) {
  // 編集モード
  const [editMode, setEditMode] = useState(true);
  // 現在のデータ
  const [currentData, setCurrentData] = useState();
  // モーダル
  const [modal, setModal] = useState(false);
  // 確認ダイアログ
  const [dialogConfirm, setDialogConfirm] = useState(false);
  // 確認ダイアログ
  const [dialogCancel, setDialogCancel] = useState(false);
  // エラーメッセージ
  const [errMessage, setErrMessage] = useState("");

  // バリデーション
  const { handleSubmit, register, formState, setValue, control } = useForm();

  // 生年月日
  const [birthDay, setBirthDay] = useState(dayjs("1980-01-01"));
  // アイコンパス
  const [temporaryPath, setTemporaryPath] = useState("");
  const [sourceTemporaryPath, setSourceTemporaryPath] = useState("");
  const [temporaryUrl, setTemporaryUrl] = useState("");

  // ルーター
  const navigate = useNavigate();
  const location = useLocation();
  // Cookie
  const [cookies, setCookie, removeCookie] = useCookies();
  // CSRFトークン
  const [csrfToken, setCsrfToken] = useState("");

  // 初期表示
  useEffect(() => {
    if (!location.state) {
      navigate("/");
      return;
    }

    props.getCsrfToken(setCsrfToken);
    if (location.state.refMode) {
      // 編集モードをOFF
      setEditMode(false);

      // 情報取得
      const auth = getAuth();
      onAuthStateChanged(auth, (userObj) => {
        if (!userObj || !cookies["login"]) {
          return;
        }
        setModal(true);
        userObj
          .getIdToken()
          .then(function (idToken) {
            (async () => {
              try {
                const response = await fetch(
                  `${SERVER_HOST}/api/user/person?base_person_id=${location.state.personId}`,
                  {
                    method: "GET",
                    headers: {
                      "Content-Type": "application/json",
                      "Custom-Fb-Token": idToken,
                    },
                    credentials: "include",
                  }
                );
                setModal(false);

                if (response.ok) {
                  const data = await response.json();
                  // フォームに設定
                  for (var key in data.base) {
                    if (key === "thumbnail_path" || key === "thumbnail_url") {
                      continue;
                    }
                    setValue(key, data.base[key]);
                  }

                  setBirthDay(dayjs(data.base.birth_day, ja));
                  setTemporaryPath(data.base.thumbnail_path);
                  setSourceTemporaryPath(`${data.base.thumbnail_path}`);
                  setTemporaryUrl(data.base.thumbnail_url);

                  setCurrentData(data.base);
                } else {
                  props.errorCommon(response);
                }
              } catch (error) {
                console.log(error);
              }
            })();
          })
          .catch(function (error) {
            // Handle error
          });
      });
    }
  }, []);

  // 画像ファイル選択処理
  const selectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }

    const formData = new FormData();
    formData.append("file", e.target.files[0]);

    const auth = getAuth();
    onAuthStateChanged(auth, (userObj) => {
      if (!userObj || !cookies["login"]) {
        return;
      }
      userObj
        .getIdToken()
        .then((idToken) => {
          (async () => {
            try {
              const response = await fetch(
                `${SERVER_HOST}/api/picture/square`,
                {
                  method: "POST",
                  headers: {
                    "Custom-Fb-Token": idToken,
                    "X-CSRF-Token": csrfToken,
                  },
                  credentials: "include",
                  body: formData,
                }
              );

              if (response.ok) {
                const data = await response.json();
                setTemporaryPath(data.temporary_path);
                setTemporaryUrl(data.url);
              } else {
                props.errorCommon(response);
              }
            } catch (error) {
              console.log(error);
            }
          })();
        })
        .catch((error) => {
          // Handle error
        });
    });
  };

  // 登録
  const insert = (data) => {
    setErrMessage("");
    const param = {
      ...data,
      base_person_id: location.state.personId,
      birth_day: birthDay.format("YYYY-MM-DD"),
      next_thumbnail_path: temporaryPath,
    };

    const auth = getAuth();
    onAuthStateChanged(auth, (userObj) => {
      if (!userObj || !cookies["login"]) {
        return;
      }
      userObj
        .getIdToken()
        .then((idToken) => {
          (async () => {
            try {
              const response = await fetch(`${SERVER_HOST}/api/user/person`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  "Custom-Fb-Token": idToken,
                  "X-CSRF-Token": csrfToken,
                },
                credentials: "include",
                body: JSON.stringify(param),
              });

              if (response.ok) {
                const data = await response.json();

                navigate("/", {
                  state: {
                    personId: data.person_id,
                    snackMessage: "登録しました。",
                  },
                });
              } else {
                props.errorCommon(response);
              }
            } catch (error) {
              console.log(error);
            }
          })();
        })
        .catch((error) => {
          // Handle error
        });
    });
  };

  // 更新
  const update = (data) => {
    const param = {
      ...data,
      base_person_id: location.state.personId,
      birth_day: birthDay.format("YYYY-MM-DD"),
      next_thumbnail_path:
        sourceTemporaryPath !== temporaryPath ? temporaryPath : "",
    };

    const auth = getAuth();
    onAuthStateChanged(auth, (userObj) => {
      if (!userObj || !cookies["login"]) {
        return;
      }
      userObj
        .getIdToken()
        .then((idToken) => {
          (async () => {
            try {
              const response = await fetch(
                `${SERVER_HOST}/api/user/person/${location.state.personId}`,
                {
                  method: "PUT",
                  headers: {
                    "Content-Type": "application/json",
                    "Custom-Fb-Token": idToken,
                    "X-CSRF-Token": csrfToken,
                  },
                  credentials: "include",
                  body: JSON.stringify(param),
                }
              );

              if (response.ok) {
                navigate("/", {
                  state: {
                    personId: location.state.personId,
                    snackMessage: "更新しました。",
                  },
                });
              } else {
                props.errorCommon(response);
              }
            } catch (error) {
              console.log(error);
            }
          })();
        })
        .catch((error) => {
          // Handle error
        });
    });
  };

  // 削除
  const deletePerson = () => {
    const auth = getAuth();
    onAuthStateChanged(auth, (userObj) => {
      if (!userObj || !cookies["login"]) {
        return;
      }
      userObj
        .getIdToken()
        .then((idToken) => {
          (async () => {
            try {
              const response = await fetch(
                `${SERVER_HOST}/api/user/person/${location.state.personId}`,
                {
                  method: "DELETE",
                  headers: {
                    "Content-Type": "application/json",
                    "Custom-Fb-Token": idToken,
                    "X-CSRF-Token": csrfToken,
                  },
                  credentials: "include",
                }
              );

              if (response.ok) {
                navigate("/", {
                  state: {
                    personId: "",
                    snackMessage: "削除しました。",
                  },
                });
              } else {
                props.errorCommon(response);
              }
            } catch (error) {
              console.log(error);
            }
          })();
        })
        .catch((error) => {
          // Handle error
        });
    });
  };

  // 描画
  return (
    <React.Fragment>
      <AuthLayout modal={modal} onCloseModal={setModal} csrfToken={csrfToken}>
        <Container maxWidth="sm" component="main" sx={{ p: 10 }}>
          <Grid container spacing={2}>
            {(() => {
              if (location.state.refMode) {
                return <Grid item xs={12} sx={{ mt: 30 }}></Grid>;
              } else {
                return (
                  <Grid item xs={12} sx={{ mt: 30 }}>
                    <MySelect
                      register={register("relation_ship", {
                        ...VALIDATION_REQUIRED,
                      })}
                      control={control}
                      editMode={editMode}
                      required
                      label={`「${location.state.personName}」さんとの関係`}
                      placeholder="関係性を選択"
                      targetList={{
                        10: "親",
                        21: "兄弟",
                        31: "配偶者",
                        41: "子供",
                        81: "親族",
                        91: "友人",
                      }}
                      error={formState.errors.relation_ship}
                    ></MySelect>
                  </Grid>
                );
              }
            })()}
            <Grid item xs={12} sx={{ mt: 10 }}>
              <MyText
                register={register("person_name", {
                  ...VALIDATION_REQUIRED,
                  ...VALIDATION_NAME,
                })}
                formState={formState}
                editMode={editMode}
                required
                label="お名前"
                error={formState.errors.person_name}
              ></MyText>
            </Grid>
            <Grid item xs={12} sx={{ mt: 10, px: 6, width: "100%" }}>
              <MyPictureSelect
                editMode={editMode}
                label="アイコン"
                size="40%"
                url={temporaryUrl}
                onChange={selectFile}
              ></MyPictureSelect>
            </Grid>
            <Grid item xs={12} sx={{ mt: 10 }}>
              <MyDateSelect
                editMode={editMode}
                required
                label="生年月日"
                placeholder="生年月日を入力"
                value={birthDay}
                onChange={setBirthDay}
              ></MyDateSelect>
            </Grid>
            <Grid item xs={12} sx={{ mt: 10 }}>
              <MySelect
                register={register("gender", {
                  ...VALIDATION_REQUIRED,
                })}
                control={control}
                editMode={editMode}
                label="性別"
                required
                placeholder="性別を選択"
                targetList={{
                  1: "男",
                  2: "女",
                  U: "その他",
                }}
                error={formState.errors.gender}
              ></MySelect>
            </Grid>
            <Grid item xs={12} sx={{ mt: 10 }}>
              <MyText
                register={register("tel_no", {
                  ...VALIDATION_REQUIRED,
                  ...VALIDATION_TEL_NO,
                })}
                control={control}
                editMode={editMode}
                required
                label="連絡先"
                placeholder="電話番号を入力"
                error={formState.errors.tel_no}
              ></MyText>
            </Grid>
            <Grid item xs={12} sx={{ mt: 10 }}>
              <MySelect
                register={register("contact_timing", {
                  ...VALIDATION_REQUIRED,
                })}
                control={control}
                editMode={editMode}
                required
                label="連絡タイミング"
                placeholder="連絡タイミングを選択"
                targetList={{
                  1: "入院時に連絡",
                  2: "施設入居時に連絡",
                  3: "死亡時に連絡",
                }}
                error={formState.errors.contact_timing}
              ></MySelect>
            </Grid>
          </Grid>
          {(() => {
            // 編集モード
            if (editMode) {
              if (location.state.refMode) {
                // 変更時

                return (
                  <React.Fragment>
                    <Grid item xs={12} sx={{ mt: 20 }}>
                      <MyButtonExec
                        label="変更する"
                        onClick={handleSubmit(update)}
                      ></MyButtonExec>
                    </Grid>
                    <Grid item xs={12}>
                      {(() => {
                        if (errMessage) {
                          return <Alert severity="error">{errMessage}</Alert>;
                        }
                      })()}
                    </Grid>
                    <Box height={120}></Box>

                    <Zoom in={true}>
                      <Fab
                        size="small"
                        sx={{
                          position: "fixed",
                          bottom: 20,
                          right: 20,
                        }}
                        color="primary"
                        onClick={() => setDialogCancel(true)}
                      >
                        <EditOffIcon />
                      </Fab>
                    </Zoom>
                  </React.Fragment>
                );
              } else {
                // 新規登録時

                return (
                  <React.Fragment>
                    <Grid item xs={12} sx={{ mt: 20, mb: -10 }}>
                      {(() => {
                        if (errMessage) {
                          return <Alert severity="error">{errMessage}</Alert>;
                        }
                      })()}
                    </Grid>
                    <Grid item xs={12} sx={{ mt: 20 }}>
                      <MyButtonExec
                        label="登録する"
                        onClick={handleSubmit(insert)}
                      ></MyButtonExec>
                    </Grid>
                    <Box height={120}></Box>
                    <Zoom in={true}>
                      <Fab
                        size="small"
                        sx={{
                          position: "fixed",
                          bottom: 20,
                          left: 20,
                        }}
                        color="primary"
                        onClick={() =>
                          navigate("/", {
                            state: { personId: location.state.personId },
                          })
                        }
                      >
                        <ArrowBackIcon />
                      </Fab>
                    </Zoom>
                  </React.Fragment>
                );
              }
            } else {
              // 参照モード
              return (
                <React.Fragment>
                  <Box height={120}></Box>
                  <Zoom in={true}>
                    <Fab
                      size="small"
                      sx={{
                        position: "fixed",
                        bottom: 80,
                        right: 20,
                      }}
                      color="primary"
                      onClick={() => setDialogConfirm(true)}
                    >
                      <DeleteIcon />
                    </Fab>
                  </Zoom>
                  <Zoom in={true}>
                    <Fab
                      size="small"
                      sx={{
                        position: "fixed",
                        bottom: 20,
                        right: 20,
                      }}
                      color="primary"
                      onClick={() => setEditMode(!editMode)}
                    >
                      <EditIcon />
                    </Fab>
                  </Zoom>
                  <Zoom in={true}>
                    <Fab
                      size="small"
                      sx={{
                        position: "fixed",
                        bottom: 20,
                        left: 20,
                      }}
                      color="primary"
                      onClick={() =>
                        navigate("/", {
                          state: { personId: location.state.personId },
                        })
                      }
                    >
                      <ArrowBackIcon />
                    </Fab>
                  </Zoom>
                </React.Fragment>
              );
            }
          })()}
        </Container>
        <Dialog open={dialogConfirm} scroll="paper" sx={{ mx: -6 }}>
          <DialogContent>この人物を削除しますか？</DialogContent>
          <DialogActions>
            <Button
              variant="text"
              onClick={() => {
                setDialogConfirm(false);
              }}
            >
              キャンセル
            </Button>
            <Button
              variant="text"
              onClick={() => {
                setDialogConfirm(false);
                deletePerson();
              }}
            >
              削除
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={dialogCancel} scroll="paper" sx={{ mx: -6 }}>
          <DialogContent>編集中の内容を破棄しますか？</DialogContent>
          <DialogActions>
            <Button variant="text" onClick={() => setDialogCancel(false)}>
              キャンセル
            </Button>
            <Button
              variant="text"
              onClick={() => {
                if (editMode) {
                  for (var key in currentData) {
                    if (key === "thumbnail_path" || key === "thumbnail_url") {
                      continue;
                    }
                    setValue(key, currentData[key]);
                  }

                  setBirthDay(dayjs(currentData.birth_day));
                  setTemporaryPath(currentData.thumbnail_path);
                  setSourceTemporaryPath(currentData.thumbnail_path);
                  setTemporaryUrl(currentData.thumbnail_url);
                }

                setDialogCancel(false);
                setEditMode(!editMode);
              }}
            >
              ＯＫ
            </Button>
          </DialogActions>
        </Dialog>
      </AuthLayout>
    </React.Fragment>
  );
}

export default Person;
