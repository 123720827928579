import React from "react";

import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";

// MyTextMultiメイン
function MyTextMulti(props) {
  return (
    <React.Fragment>
      <Typography sx={{ fontWeight: "bold" }}>{props.label}</Typography>
      {(() => {
        if (props.editMode) {
          return (
            <React.Fragment>
              <Typography variant="caption">
                {props.required ? "※必須" : "※任意"}
              </Typography>
            </React.Fragment>
          );
        } else {
          return;
        }
      })()}
      <TextField
        {...props.register}
        variant="standard"
        fullWidth
        multiline
        minRows={props.minRows}
        maxRows={props.maxRows}
        placeholder={props.editMode ? props.placeholder : ""}
        inputProps={{ readOnly: !props.editMode }}
        error={props.error}
        helperText={props.error?.message}
      />
    </React.Fragment>
  );
}

export default MyTextMulti;
