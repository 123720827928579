import "../../App.css";

import React from "react";
import dayjs from "dayjs";

import { getFormatTelNo } from "../../func";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import Avatar from "@mui/material/Avatar";
import CardMedia from "@mui/material/CardMedia";
import Stack from "@mui/material/Stack";

import { ArrowLeftIcon } from "@mui/x-date-pickers";
import { ArrowRightIcon } from "@mui/x-date-pickers";
import EditIcon from "@mui/icons-material/Edit";

// MyFamilyFrameメイン
function MyFamilyFrame(props) {
  const brothersWithBase = props.brothers.map((data) => {
    return {
      ...data,
      sort_key: data.birth_day + "-" + ("000000" + data.regist_num).slice(-6),
    };
  });
  brothersWithBase.push({
    ...props.base,
    sort_key:
      props.base.birth_day + "-" + ("000000" + props.base.regist_num).slice(-6),
    is_base: true,
  });

  brothersWithBase.sort((a, b) => (a.sort_key > b.sort_key ? 1 : -1));
  var targetIndex = 0;
  for (targetIndex; targetIndex < brothersWithBase.length; targetIndex++) {
    if (brothersWithBase[targetIndex].is_base) {
      break;
    }
  }
  const currentIndex = targetIndex;

  // 兄弟取得
  const getBrotherTargetPersonId = (delta) => {
    const targetIndex = currentIndex + delta;
    if (targetIndex < 0 || targetIndex >= brothersWithBase.length) {
      return;
    }

    return brothersWithBase[currentIndex + delta].person_id;
  };

  // 描画
  return (
    <React.Fragment>
      <Grid container>
        <Grid item container>
          <Grid item xs={4}>
            {(() => {
              if (props.father?.person_id) {
                return (
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                    onClick={() => {
                      props.switchTarget(props.father.person_id);
                    }}
                  >
                    {props.father.person_name}
                  </Typography>
                );
              } else {
                return (
                  <Typography
                    sx={{
                      fontStyle: "italic",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    （未登録）
                  </Typography>
                );
              }
            })()}
          </Grid>
          <Grid item xs={4}></Grid>
          <Grid item xs={4}>
            {(() => {
              if (props.mother?.person_id) {
                return (
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                    onClick={() => {
                      props.switchTarget(props.mother.person_id);
                    }}
                  >
                    {props.mother.person_name}
                  </Typography>
                );
              } else {
                return (
                  <Typography
                    sx={{
                      fontStyle: "italic",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    （未登録）
                  </Typography>
                );
              }
            })()}
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            mt: 4,
            width: "auto",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img
            src="../parentLine.png"
            width="70%"
            height="100%"
            alt="memo"
          ></img>
        </Grid>
        <Grid item container justifyContent="center" xs={12} sx={{ mt: 10 }}>
          <Grid item xs={3}>
            <CardMedia
              component="img"
              image={`${props.base.thumbnail_url}`}
              sx={{
                width: "auto",
                height: "auto",
                maxWidth: "115%",
                maxWeight: "115%",
              }}
              alt="loading..."
              onClick={props.toNote}
            />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item container xs={8} sx={{ ml: -2 }}>
            <Grid item container xs={12}>
              <Grid item xs={9} sx={{ mt: -2 }}>
                <Typography
                  variant="h5"
                  sx={{ fontWeight: "bold", mb: 4 }}
                  onClick={props.toNote}
                >
                  {props.base.person_name}
                </Typography>
                <div />

                <Typography variant="body2" sx={{ fontWeight: "bold", mb: 2 }}>
                  {props.base.birth_day
                    ? dayjs(props.base.birth_day).format("YYYY年M月D日生まれ")
                    : ""}
                </Typography>
                <div />
                <Typography variant="body2" sx={{ fontWeight: "bold", mb: 2 }}>
                  {getFormatTelNo(props.base.tel_no)}
                </Typography>
                <div />
                {(() => {
                  if (!props.isUser) {
                    return (
                      <Grid item xs={12} sx={{ mt: 4 }}>
                        <EditIcon onClick={props.onClickEditIcon}></EditIcon>
                      </Grid>
                    );
                  }
                })()}
              </Grid>
              <Grid item xs={3}>
                {(() => {
                  if (props.partner?.thumbnail_url) {
                    return (
                      <Avatar
                        alt={props.partner.person_name}
                        src={`${props.partner.thumbnail_url}`}
                        sx={{ mt: 2, width: 64, height: 64 }}
                        onClick={() => {
                          props.switchTarget(props.partner.person_id);
                        }}
                      />
                    );
                  } else {
                    return;
                  }
                })()}
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ mt: 10 }}>
              {props.children.map((data, index) => {
                return (
                  <React.Fragment key={index}>
                    <Chip
                      avatar={
                        <Avatar alt="Natacha" src={`${data.thumbnail_url}`} />
                      }
                      label={data.person_name}
                      onClick={() => {
                        props.switchTarget(data.person_id);
                      }}
                      sx={{ mb: 2, mr: 1 }}
                    ></Chip>
                  </React.Fragment>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ mt: 10, px: 12 }}>
          <Stack
            direction="row"
            spacing={10}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Avatar
              color="primary"
              sx={{ width: 14, height: 14, bgcolor: "#3F3F3F" }}
              onClick={() => {
                var targetPersonId = getBrotherTargetPersonId(-1);
                if (targetPersonId) {
                  props.switchTarget(targetPersonId);
                }
              }}
            >
              <ArrowLeftIcon sx={{ fontSize: "14pt" }} />
            </Avatar>
            {brothersWithBase.map((data, index) => {
              return (
                <React.Fragment key={index}>
                  <Avatar
                    onClick={() => {
                      props.switchTarget(data.person_id);
                    }}
                    sx={{
                      width: 14,
                      height: 14,
                      bgcolor: data.is_base ? "#3F3F3F" : "#999090",
                      color: data.is_base ? "#3F3F3F" : "#999090",
                    }}
                  ></Avatar>
                </React.Fragment>
              );
            })}
            <Avatar
              sx={{ width: 14, height: 14, bgcolor: "#3F3F3F", ml: 10 }}
              onClick={() => {
                var targetPersonId = getBrotherTargetPersonId(1);
                if (targetPersonId) {
                  props.switchTarget(targetPersonId);
                }
              }}
            >
              <ArrowRightIcon sx={{ fontSize: "14pt" }} />
            </Avatar>
          </Stack>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default MyFamilyFrame;
