export const VALIDATION_REQUIRED = {
  required: {
    value: true,
    message: "入力必須です",
  },
};

export const VALIDATION_NAME = {
  maxLength: {
    value: 20,
    message: "20文字以内で入力してください",
  },
};

export const VALIDATION_CORPORATION_NAME = {
  maxLength: {
    value: 20,
    message: "20文字以内で入力してください",
  },
};

export const VALIDATION_ADDRESS = {
  maxLength: {
    value: 40,
    message: "40文字以内で入力してください",
  },
};

export const VALIDATION_TEL_NO = {
  pattern: {
    value: /^[0-9]+$/,
    message: "半角数字(ハイフンなし)で入力してください。",
  },
  maxlength: {
    value: 11,
    message: "11文字以内で入力してください",
  },
};

export const VALIDATION_EMAIL = {
  pattern: {
    value: /^[\w\-._]+@[\w\-._]+\.[A-Za-z]+/,
    message: "メールアドレス形式で入力してください。",
  },
  maxlength: {
    value: 255,
    message: "255文字以内で入力してください",
  },
};

export const VALIDATION_ID_NUMBER = {
  pattern: {
    value: /^[a-zA-Z0-9 -/:-@\[-\`\{-\~]+$/,
    message: "半角英数記号で入力してください。",
  },
  maxlength: {
    value: 11,
    message: "11文字以内で入力してください",
  },
};

export const VALIDATION_GENERAL_VALUE = {
  maxLength: {
    value: 255,
    message: "255文字以内で入力してください",
  },
};

export const VALIDATION_HISTORY_TITLE = {
  maxLength: {
    value: 50,
    message: "50文字以内で入力してください",
  },
};

export const VALIDATION_HISTORY_CONTENT = {
  maxLength: {
    value: 2048,
    message: "2048文字以内で入力してください",
  },
};
