import { createTheme } from "@mui/material/styles";

import { pink, red, grey, green, orange } from "@mui/material/colors";

// テーマ
export const myTheme = createTheme({
  spacing: 2,
  typography: {
    fontFamily: ["Noto Sans JP", "Poppins", "sans-serif"].join(","),
    fontSize: 14,
  },
  palette: {
    primary: {
      main: "#3F3F3F",
    },
    secondary: {
      main: "#FFFFFF",
    },
    appBar: {
      main: "#fff",
    },
    macrophone: {
      main: red[700],
    },
    background: {
      default: "#fff",
    },
    endButton: {
      main: pink[900],
    },
    border: {
      main: grey[300],
    },
    user: {
      main: "#DCE2FF",
    },
    resultOk: {
      main: green[50],
    },
    resultNg: {
      main: orange[50],
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          // Some CSS
          fontSize: "1.125rem",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          border: "none",
          zIndex: 0,
        },
      },
    },
  },
});

// サーバHOST
export const SERVER_HOST = process.env.REACT_APP_API_HOST;

export const VALIDATE_REQUIRED = {
  value: true,
  message: "入力必須です",
};

export const VALIDATE_MAXLENGTH_VARIABLE_NAME = {
  value: 40,
  message: "40桁以内で入力してください",
};

export const VALIDATE_MAXLENGTH_VARIABLE_NUMBER = {
  value: 40,
  message: "40桁以内で入力してください",
};

// History１ページ当たりのリスト件数
export const HISTORY_PAGE_LIMIT = 5;
