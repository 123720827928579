import React from "react";
import { Controller } from "react-hook-form";

import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";

// MySelectメイン
function MySelect(props) {
  return (
    <React.Fragment>
      <Typography sx={{ fontWeight: "bold" }}>{props.label}</Typography>
      {(() => {
        if (props.editMode) {
          return (
            <Typography variant="caption">
              {props.required ? "※必須" : "※任意"}
            </Typography>
          );
        } else {
          return;
        }
      })()}
      <Controller
        name={props.register.name}
        control={props.control}
        defaultValue=""
        render={({ field: { onChange, value } }) => (
          <Select
            label={props.label}
            variant="standard"
            value={value}
            displayEmpty
            defaultValue=""
            onChange={onChange}
            renderValue={(selected) => {
              if (!selected) {
                return (
                  <Typography sx={{ fontWeight: "200" }}>
                    {props.editMode ? props.placeholder : ""}
                  </Typography>
                );
              }

              return props.targetList[selected];
            }}
            inputProps={{ readOnly: !props.editMode }}
            fullWidth
            sx={{ pt: 2 }}
            error={props.error}
          >
            {(() => {
              if (!props.required) {
                return <MenuItem id="menunone" value=""></MenuItem>;
              }
            })()}
            {Object.entries(props.targetList).map((data, index) => {
              return (
                <MenuItem id={`menu${index}`} value={String(data[0])}>
                  {data[1]}
                </MenuItem>
              );
            })}
          </Select>
        )}
      />
      <FormHelperText sx={{ color: "#d92f6b" }}>
        {props.error?.message}
      </FormHelperText>
    </React.Fragment>
  );
}

export default MySelect;
