import { PhoneNumberUtil, PhoneNumberFormat } from "google-libphonenumber";

const phoneUtil = PhoneNumberUtil.getInstance();

export const editDate = (dateStr) => {
  if (!dateStr) {
    return "";
  }
  return `${dateStr.substring(0, 4)}年${dateStr.substring(
    4,
    6
  )}月${dateStr.substring(6, 8)}日`;
};

export const getFormatTelNo = (telNo) => {
  if (!telNo) {
    return "";
  }
  try {
    const number = phoneUtil.parseAndKeepRawInput(telNo, "JP");
    return phoneUtil.format(number, PhoneNumberFormat.NATIONAL);
  } catch {
    return telNo;
  }
};
