import "../App.css";

import React, { useState, useEffect } from "react";
import {
  getAuth,
  signInWithPhoneNumber,
  RecaptchaVerifier,
  onAuthStateChanged,
} from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { SERVER_HOST } from "../constant";
import Container from "@mui/material/Container";

import NoAuthLayout from "../components/layout/NoAuthLayout.js";
import LoginStepFirst from "../components/LoginStepFirst";
import LoginStepSecond from "../components/LoginStepSecond";
import LoginStepThird from "../components/LoginStepThird";
import { useCookies } from "react-cookie";
import { PhoneNumberUtil, PhoneNumberFormat } from "google-libphonenumber";

let recaptchaVerifier = null;
let recaptchaId = null;

// Loginメイン
function Login(props) {
  // ステップ
  const [step, setStep] = useState(1);
  // ID
  const [userId, setUserId] = useState("");
  // パスワード
  const [password, setPassword] = useState("");
  // 携帯電話番号
  const [phoneNumber, setPhoneNumber] = useState("");
  // 認証コード
  const [authCode, setAuthCode] = useState("");
  // メッセージ
  const [message, setMessage] = useState("");
  // recaptchaカウンタ
  const [recaptchaCount, setRecaptchaCount] = useState(0);

  // ルーター
  const navigate = useNavigate();
  // Cookie
  const [cookies, setCookie, removeCookie] = useCookies();

  // CSRFトークン
  const [csrfToken, setCsrfToken] = useState("");

  // 認証ID
  const [currentConfirmationResult, setCurrentConfirmationResult] = useState();

  // 初期表示
  useEffect(() => {
    removeCookie("login");
    setRecaptcha();
    props.getCsrfToken(setCsrfToken);
  }, []);

  const setRecaptcha = async () => {
    const auth = getAuth();
    if (recaptchaVerifier != null) {
      recaptchaVerifier.clear();
      recaptchaVerifier = null;
    }
    recaptchaVerifier = new RecaptchaVerifier(auth, "recaptcha-container-id", {
      size: "invisible",
    });
    recaptchaId = await recaptchaVerifier.render();
    console.log(recaptchaId);
  };

  const login = () => {
    setMessage("");
    const param = {
      space_id: userId,
      password: password,
    };

    (async () => {
      try {
        const response = await fetch(`${SERVER_HOST}/api/auth/login`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": csrfToken,
          },
          credentials: "include",
          body: JSON.stringify(param),
        });

        if (response.ok) {
          setCookie("login", true, {
            maxAge: Number(process.env.REACT_APP_COOKIE_MAX_AGE),
          });

          // 次のプロセスへ
          setStep(2);
        } else if (response.status === 400) {
          // エラーメッセージを表示
          const errorData = await response.json();
          setMessage(errorData.detail);
          return;
        } else {
          console.log(response);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  };

  const authPhoneNumber = () => {
    setMessage("");

    const auth = getAuth();
    console.log(auth.app);
    const phoneUtil = PhoneNumberUtil.getInstance();
    const parsePhoneNumber = phoneUtil.parseAndKeepRawInput(phoneNumber, "JP");
    if (!phoneUtil.isValidNumberForRegion(parsePhoneNumber, "JP")) {
      // エラーメッセージ
      setMessage("電話番号が誤っています。");
      return;
    }
    const formatPhoneNumber = phoneUtil.format(
      parsePhoneNumber,
      PhoneNumberFormat.E164
    );

    signInWithPhoneNumber(auth, formatPhoneNumber, recaptchaVerifier)
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        setCurrentConfirmationResult(confirmationResult);

        setStep(3);
        // ...
      })
      .catch((error) => {
        console.log(error);
        // // Recaptchaのリセット
        // recaptchaVerifier.render().then(function (widgetId) {
        //   recaptchaVerifier.reset(widgetId);
        // });

        // エラーメッセージ
        setMessage("認証エラー");
      });
  };

  const verifyCode = () => {
    setMessage("");

    currentConfirmationResult
      .confirm(authCode)
      .then((result) => {
        // User signed in successfully.
        setTimeout(() => {
          navigate("/", { state: { personId: "" } });
        }, 500);
        // ...
      })
      .catch((error) => {
        // エラーメッセージ
        setMessage("認証エラー");
      });
  };

  // 描画
  return (
    <React.Fragment>
      <NoAuthLayout>
        <div className="container" id="recaptcha-container-id"></div>
        <Container maxWidth="sm" component="main">
          <div className="resizeimage">
            <img src="../login.jpeg" alt="memo"></img>
          </div>
        </Container>
        <Container maxWidth="sm" component="main" sx={{ p: 10 }}>
          {(() => {
            if (step === 1) {
              return (
                <LoginStepFirst
                  onChangeUserId={setUserId}
                  onChangePassword={setPassword}
                  onClick={login}
                  message={message}
                ></LoginStepFirst>
              );
            } else if (step === 2) {
              return (
                <LoginStepSecond
                  telNo={phoneNumber}
                  onChangeTelNo={setPhoneNumber}
                  onClick={authPhoneNumber}
                  message={message}
                ></LoginStepSecond>
              );
            } else if (step === 3) {
              return (
                <LoginStepThird
                  onChangeAuthCode={setAuthCode}
                  onClick={verifyCode}
                  backStep={() => setStep(2)}
                  message={message}
                ></LoginStepThird>
              );
            }
          })()}
        </Container>
      </NoAuthLayout>
    </React.Fragment>
  );
}

export default Login;
