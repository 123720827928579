import React from "react";

import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";

import Grid from "@mui/material/Grid";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import Tooltip from "@mui/material/Tooltip";

// MyTestメイン
function MyText(props) {
  return (
    <React.Fragment>
      <Grid container spacing={0}>
        <Grid item>
          <Typography sx={{ fontWeight: "bold" }}>{props.label}</Typography>
        </Grid>
        {(() => {
          if (props.editMode && props.helpTip) {
            return (
              <Grid item sx={{ ml: 4 }}>
                <Tooltip enterTouchDelay="100" title={props.helpTip}>
                  <HelpOutlineOutlinedIcon
                    fontSize="small"
                    sx={{ mt: 1 }}
                  ></HelpOutlineOutlinedIcon>
                </Tooltip>
              </Grid>
            );
          }
        })()}
      </Grid>
      {(() => {
        if (props.editMode) {
          return (
            <React.Fragment>
              <Typography variant="caption">
                {props.required ? "※必須" : "※任意"}
              </Typography>
            </React.Fragment>
          );
        } else {
          return;
        }
      })()}
      <TextField
        {...props.register}
        variant="standard"
        fullWidth
        placeholder={props.editMode ? props.placeholder : ""}
        inputProps={{ readOnly: !props.editMode }}
        error={props.error}
        helperText={props.error?.message}
        sx={{ pt: 2 }}
      />
    </React.Fragment>
  );
}

export default MyText;
