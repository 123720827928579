import "../../App.css";

import React, { useState } from "react";

import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import CardMedia from "@mui/material/CardMedia";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Chip from "@mui/material/Chip";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";

// MyHistoryDialogメイン
function MyHistoryDialog(props) {
  const [dialogConfirm, setDialogConfirm] = useState(false);

  // 描画
  return (
    <React.Fragment>
      <DialogTitle>
        <CardMedia
          component="img"
          image={`${props.target.picture_url}`}
          sx={{
            width: "auto",
            height: "auto",
            maxWidth: "100%",
            maxWeight: "100%",
            mb: 6,
          }}
          alt="nodata"
        />
        <Typography variant="h5" sx={{ fontWeight: "bold", mb: 4 }}>
          {props.target.title}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body2" sx={{ whiteSpace: "pre-line" }}>
          {props.target.content}
        </Typography>
        <Box sx={{ mt: 10 }}>
          {props.target.companion_list.map((data, index) => {
            return (
              <React.Fragment>
                <Chip
                  avatar={
                    <Avatar alt="Natacha" src={`${data.thumbnail_url}`} />
                  }
                  label={data.person_name}
                  sx={{ mb: 2, mr: 1 }}
                ></Chip>
              </React.Fragment>
            );
          })}
        </Box>
      </DialogContent>
      <DialogActions>
        <EditIcon onClick={props.onClickEditIcon} />
        <DeleteIcon
          onClick={() => {
            setDialogConfirm(true);
          }}
        />
      </DialogActions>
      <Dialog open={dialogConfirm} scroll="paper" sx={{ mx: -6 }}>
        <DialogContent>この記事を削除しますか？</DialogContent>
        <DialogActions>
          <Button
            variant="text"
            onClick={() => {
              setDialogConfirm(false);
            }}
          >
            キャンセル
          </Button>
          <Button
            variant="text"
            onClick={() => {
              setDialogConfirm(false);
              props.deleteHistory(props.target.history_id);
            }}
          >
            削除
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default MyHistoryDialog;
