import React from "react";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";

// LoginStepThirdメイン
function LoginStepThrid(props) {
  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Typography
          variant="h5"
          sx={{
            fontWight: "bold",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mb: 6,
          }}
        >
          認証プロセス
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {(() => {
          if (props.message) {
            return (
              <Alert severity="error" sx={{ mb: 4 }}>
                {props.message}
              </Alert>
            );
          }
        })()}
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="body2"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mb: 6,
          }}
        >
          携帯電話のSMSに届いたコードを入力してください
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="コードを入力"
          value={props.authCode}
          variant="standard"
          fullWidth
          onChange={(event) => props.onChangeAuthCode(event.target.value)}
        />
      </Grid>
      <Grid item xs={12} sx={{ mt: 16 }}>
        <Button
          size="12"
          variant="text"
          color="primary"
          onClick={props.backStep}
          className="effect-btn"
          sx={{ borderRadius: 2, fontSize: "11pt" }}
        >
          携帯電話番号を再入力する
        </Button>
      </Grid>
      <Grid item xs={12} sx={{ mt: 30, px: 12, width: "100%" }}>
        <Button
          size="large"
          variant="contained"
          color="primary"
          fullWidth
          disableElevation
          onClick={props.onClick}
          className="effect-btn"
          sx={{ borderRadius: 2 }}
        >
          次へ
        </Button>
      </Grid>
    </React.Fragment>
  );
}

export default LoginStepThrid;
