import "../App.css";

import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm, useFieldArray } from "react-hook-form";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";

import { SERVER_HOST } from "../constant";
import {
  VALIDATION_REQUIRED,
  VALIDATION_NAME,
  VALIDATION_ADDRESS,
  VALIDATION_GENERAL_VALUE,
} from "../constantBiz";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionActions from "@mui/material/AccordionActions";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import AuthLayout from "../components/layout/AuthLayout.js";
import MyText from "../components/MyText.js";
import MySelect from "../components/MySelect.js";
import MyNoteActionFrame from "../components/template/MyNoteActionFrame.js";
import { useCookies } from "react-cookie";

// NotePossessionメイン
function NotePossession(props) {
  // ルーター
  const navigate = useNavigate();
  const location = useLocation();
  // 現在のデータ
  const [currentData, setCurrentData] = useState();
  // 編集モード
  const [editMode, setEditMode] = useState(false);
  // メッセージ
  const [message, setMessage] = useState("");
  // モーダル
  const [modal, setModal] = useState(false);
  // バリデーション
  const { handleSubmit, register, formState, setValue, control, getValues } =
    useForm();
  // バリデーション（リスト）
  const { fields, append, remove } = useFieldArray({
    name: "possession_list",
    control,
  });

  // Cookie
  const [cookies, setCookie, removeCookie] = useCookies();
  // CSRFトークン
  const [csrfToken, setCsrfToken] = useState("");

  // 初期表示
  useEffect(() => {
    if (!location.state) {
      navigate("/");
      return;
    }

    props.getCsrfToken(setCsrfToken);
    getInfo();
  }, []);

  // 情報取得
  const getInfo = () => {
    const auth = getAuth();
    onAuthStateChanged(auth, (userObj) => {
      if (!userObj || !cookies["login"]) {
        return;
      }
      setModal(true);
      userObj
        .getIdToken()
        .then(function (idToken) {
          (async () => {
            try {
              const response = await fetch(
                `${SERVER_HOST}/api/user/note/possession`,
                {
                  method: "GET",
                  headers: {
                    "Content-Type": "application/json",
                    "Custom-Fb-Token": idToken,
                  },
                  credentials: "include",
                }
              );

              setModal(false);
              if (response.ok) {
                const data = await response.json();
                setValue("possession_list", data.possession_list);
                setCurrentData(data);
              } else {
                props.errorCommon(response);
              }
            } catch (error) {
              console.log(error);
            }
          })();
        })
        .catch(function (error) {
          // Handle error
        });
    });
  };

  // 情報追加
  const addList = () => {
    append({});
  };

  // 情報削除
  const deleteList = (index) => {
    remove(index);
  };

  // 登録／更新
  const upsert = (data) => {
    const param = data;

    const auth = getAuth();
    onAuthStateChanged(auth, (userObj) => {
      if (!userObj || !cookies["login"]) {
        return;
      }
      setModal(true);
      userObj
        .getIdToken()
        .then((idToken) => {
          (async () => {
            try {
              const response = await fetch(
                `${SERVER_HOST}/api/user/note/possession`,
                {
                  method: "PUT",
                  headers: {
                    "Content-Type": "application/json",
                    "Custom-Fb-Token": idToken,
                    "X-CSRF-Token": csrfToken,
                  },
                  credentials: "include",
                  body: JSON.stringify(param),
                }
              );

              setModal(false);
              if (response.ok) {
                setMessage("更新しました。");
                setEditMode(false);
                getInfo();
                window.scrollTo(0, 0);
              } else {
                props.errorCommon(response);
              }
            } catch (error) {
              console.log(error);
            }
          })();
        })
        .catch((error) => {
          // Handle error
        });
    });
  };

  // 描画
  return (
    <AuthLayout
      modal={modal}
      onCloseModal={setModal}
      snackMessage={message}
      onCloseSnack={() => setMessage("")}
      csrfToken={csrfToken}
    >
      <Container maxWidth="sm" component="main" sx={{ p: 12 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sx={{ mt: 30 }}>
            <Typography
              variant="h6"
              sx={{
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              遺品について
            </Typography>
          </Grid>
          <React.Fragment>
            {(() => {
              if (fields.length === 0 && !editMode) {
                return (
                  <Grid item xs={12} sx={{ mt: 10 }}>
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      右下のボタンから遺品を追加してください
                    </Typography>
                  </Grid>
                );
              }
            })()}
            {fields.map((field, index) => (
              <>
                <Grid item xs={12} sx={{ mt: 10 }} key={index}>
                  <Accordion sx={{ border: "none" }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography
                        variant="body1"
                        sx={{ ml: 2, fontWeight: "bold" }}
                      >
                        {(() => {
                          const title = getValues(
                            `possession_list.${index}.possession_name`
                          );
                          return title ? title : `遺品 ${index + 1}`;
                        })()}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid cotainer sx={{ p: 4 }}>
                        <Grid item xs={12} sx={{ sx: 10 }}>
                          <MyText
                            key={`possession_name${field.id}`}
                            register={register(
                              `possession_list.${index}.possession_name`,
                              {
                                ...VALIDATION_REQUIRED,
                                ...VALIDATION_NAME,
                              }
                            )}
                            control={control}
                            editMode={editMode}
                            required
                            label="名称"
                            placeholder="名称を入力"
                            error={
                              formState.errors.possession_list &&
                              formState.errors.possession_list[index]
                                ? formState.errors.possession_list[index]
                                    .possession_name
                                : null
                            }
                          ></MyText>
                        </Grid>
                        <Grid item xs={12} sx={{ mt: 10 }}>
                          <MySelect
                            key={`possession_type${field.id}`}
                            register={register(
                              `possession_list.${index}.possession_type`,
                              {}
                            )}
                            control={control}
                            editMode={editMode}
                            label="種別"
                            placeholder="種別を選択"
                            targetList={{ 1: "形見である", 2: "形見でない" }}
                            error={
                              formState.errors.possession_list &&
                              formState.errors.possession_list[index]
                                ? formState.errors.possession_list[index]
                                    .possession_type
                                : null
                            }
                          ></MySelect>
                        </Grid>
                        <Grid item xs={12} sx={{ mt: 10 }}>
                          <MyText
                            key={`storage_location${field.id}`}
                            register={register(
                              `possession_list.${index}.storage_location`,
                              {
                                ...VALIDATION_ADDRESS,
                              }
                            )}
                            control={control}
                            editMode={editMode}
                            label="保管場所"
                            placeholder="保管場所を入力"
                            error={
                              formState.errors.possession_list &&
                              formState.errors.possession_list[index]
                                ? formState.errors.possession_list[index]
                                    .storage_location
                                : null
                            }
                          ></MyText>
                        </Grid>
                        <Grid item xs={12} sx={{ mt: 10 }}>
                          <MySelect
                            key={`handling${field.id}`}
                            register={register(
                              `possession_list.${index}.handling`,
                              {}
                            )}
                            control={control}
                            editMode={editMode}
                            label="取扱"
                            placeholder="取扱を選択"
                            targetList={{
                              1: "取扱",
                              2: "寄付",
                              3: "処分",
                              4: "まかせる",
                            }}
                            error={
                              formState.errors.possession_list &&
                              formState.errors.possession_list[index]
                                ? formState.errors.possession_list[index]
                                    .handling
                                : null
                            }
                          ></MySelect>
                        </Grid>
                        <Grid item xs={12} sx={{ mt: 10 }}>
                          <MyText
                            key={`memo${field.id}`}
                            register={register(
                              `possession_list.${index}.memo`,
                              {
                                ...VALIDATION_GENERAL_VALUE,
                              }
                            )}
                            control={control}
                            editMode={editMode}
                            label="備考"
                            placeholder="相続、遺贈先を入力"
                            error={
                              formState.errors.possession_list &&
                              formState.errors.possession_list[index]
                                ? formState.errors.possession_list[index].memo
                                : null
                            }
                          ></MyText>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                    <AccordionActions>
                      <DeleteIcon
                        size="large"
                        onClick={deleteList}
                      ></DeleteIcon>
                    </AccordionActions>
                  </Accordion>
                </Grid>
              </>
            ))}
          </React.Fragment>
        </Grid>
        {(() => {
          if (editMode) {
            return (
              <Grid
                item
                xs={12}
                sx={{
                  mt: 10,
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button onClick={addList} sx={{ textDecoration: "underline" }}>
                  遺品を追加する
                </Button>
              </Grid>
            );
          }
        })()}
        <MyNoteActionFrame
          editMode={editMode}
          setEditMode={() => {
            if (editMode) {
              setValue("possession_list", currentData.possession_list);
            }
            setEditMode(!editMode);
          }}
          onClickExec={handleSubmit(upsert)}
          onClickBackIcon={() =>
            navigate("/note", { state: { personId: "", note: true } })
          }
        ></MyNoteActionFrame>
      </Container>
    </AuthLayout>
  );
}

export default NotePossession;
