import "../../App.css";

import React from "react";
import dayjs from "dayjs";

import { getFormatTelNo } from "../../func";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";

// MyNoteMeFrameメイン
function MyNoteMeFrame(props) {
  // 描画
  return (
    <React.Fragment>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12}>
          <Avatar
            alt="Remy Sharp"
            src={props.base ? props.base.thumbnail_url : ""}
            sx={{ width: 150, height: 150 }}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4" sx={{ mt: 4 }}>
            {props.base.person_name}
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ mt: 4 }}>
          <Typography variant="body-2">
            {props.base.birth_day
              ? dayjs(props.base.birth_day).format("YYYY年M月D日生まれ")
              : ""}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body-2">
            {getFormatTelNo(props.base.tel_no)}
          </Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default MyNoteMeFrame;
