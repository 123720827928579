import "../App.css";

import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { useForm } from "react-hook-form";
import dayjs from "dayjs";

import { SERVER_HOST } from "../constant";
import {
  VALIDATION_REQUIRED,
  VALIDATION_NAME,
  VALIDATION_TEL_NO,
  VALIDATION_EMAIL,
  VALIDATION_ADDRESS,
  VALIDATION_CORPORATION_NAME,
  VALIDATION_GENERAL_VALUE,
} from "../constantBiz";

import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

import AuthLayout from "../components/layout/AuthLayout.js";
import MyText from "../components/MyText.js";
import MySelect from "../components/MySelect.js";
import MyDateSelect from "../components/MyDateSelect.js";
import MyPictureSelect from "../components/MyPictureSelect.js";
import MyNoteActionFrame from "../components/template/MyNoteActionFrame.js";
import { useCookies } from "react-cookie";

// NoteMeメイン
function NoteMe(props) {
  // ルーター
  const navigate = useNavigate();
  const location = useLocation();
  // 現在のデータ
  const [currentData, setCurrentData] = useState();
  // 編集モード
  const [editMode, setEditMode] = useState(false);
  // メッセージ
  const [message, setMessage] = useState("");
  // モーダル
  const [modal, setModal] = useState(false);
  // バリデーション
  const { handleSubmit, register, formState, setValue, control } = useForm();

  // 生年月日
  const [birthDay, setBirthDay] = useState(dayjs("1980-01-01"));
  // 配偶者の生年月日
  const [partnerBirthDay, setPartnerBirthDay] = useState(null);
  // アイコンパス
  const [temporaryPath, setTemporaryPath] = useState("");
  const [sourceTemporaryPath, setSourceTemporaryPath] = useState("");
  const [temporaryUrl, setTemporaryUrl] = useState("");

  // Cookie
  const [cookies, setCookie, removeCookie] = useCookies();
  // CSRFトークン
  const [csrfToken, setCsrfToken] = useState("");

  // 初期表示
  useEffect(() => {
    if (!location.state) {
      navigate("/");
      return;
    }

    props.getCsrfToken(setCsrfToken);
    getInfo();
  }, []);

  // 情報取得
  const getInfo = () => {
    const auth = getAuth();
    onAuthStateChanged(auth, (userObj) => {
      if (!userObj || !cookies["login"]) {
        return;
      }
      setModal(true);
      userObj
        .getIdToken()
        .then(function (idToken) {
          (async () => {
            try {
              const response = await fetch(`${SERVER_HOST}/api/user/note/me`, {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  "Custom-Fb-Token": idToken,
                },
                credentials: "include",
              });

              setModal(false);
              if (response.ok) {
                const data = await response.json();
                // フォームに設定
                for (var key in data) {
                  if (key === "thumbnail_path" || key === "thumbnail_url") {
                    continue;
                  }
                  setValue(key, data[key]);
                }

                setBirthDay(dayjs(data.birth_day));
                if (data.partner_birth_day) {
                  setPartnerBirthDay(dayjs(data.partner_birth_day));
                }
                setTemporaryPath(data.thumbnail_path);
                setSourceTemporaryPath(data.thumbnail_path);
                setTemporaryUrl(data.thumbnail_url);

                setCurrentData(data);
              } else {
                props.errorCommon(response);
              }
            } catch (error) {
              console.log(error);
            }
          })();
        })
        .catch(function (error) {
          // Handle error
        });
    });
  };

  // 画像ファイル選択処理
  const selectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }

    const formData = new FormData();
    formData.append("file", e.target.files[0]);

    const auth = getAuth();
    onAuthStateChanged(auth, (userObj) => {
      if (!userObj || !cookies["login"]) {
        return;
      }
      userObj
        .getIdToken()
        .then((idToken) => {
          (async () => {
            try {
              const response = await fetch(
                `${SERVER_HOST}/api/picture/square`,
                {
                  method: "POST",
                  headers: {
                    "Custom-Fb-Token": idToken,
                    "X-CSRF-Token": csrfToken,
                  },
                  credentials: "include",
                  body: formData,
                }
              );

              if (response.ok) {
                const data = await response.json();
                setTemporaryPath(data.temporary_path);
                setTemporaryUrl(data.url);
              } else {
                props.errorCommon(response);
              }
            } catch (error) {
              console.log(error);
            }
          })();
        })
        .catch((error) => {
          // Handle error
        });
    });
  };

  // 登録／更新
  const upsert = (data) => {
    const param = {
      ...data,
      birth_day: birthDay.format("YYYY-MM-DD"),
      partner_birth_day: partnerBirthDay
        ? partnerBirthDay.format("YYYY-MM-DD")
        : "",
      next_thumbnail_path:
        sourceTemporaryPath !== temporaryPath ? temporaryPath : "",
    };

    const auth = getAuth();
    onAuthStateChanged(auth, (userObj) => {
      if (!userObj || !cookies["login"]) {
        return;
      }
      userObj
        .getIdToken()
        .then((idToken) => {
          (async () => {
            try {
              const response = await fetch(`${SERVER_HOST}/api/user/note/me`, {
                method: "PUT",
                headers: {
                  "Content-Type": "application/json",
                  "Custom-Fb-Token": idToken,
                  "X-CSRF-Token": csrfToken,
                },
                credentials: "include",
                body: JSON.stringify(param),
              });

              if (response.ok) {
                setMessage("更新しました。");
                setEditMode(false);
                getInfo();
                window.scrollTo(0, 0);
              } else {
                props.errorCommon(response);
              }
            } catch (error) {
              console.log(error);
            }
          })();
        })
        .catch((error) => {
          // Handle error
        });
    });
  };

  // 描画
  return (
    <AuthLayout
      modal={modal}
      onCloseModal={setModal}
      snackMessage={message}
      onCloseSnack={() => setMessage("")}
      csrfToken={csrfToken}
    >
      <Container maxWidth="sm" component="main" sx={{ p: 12 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sx={{ mt: 30 }}>
            <Typography
              variant="h6"
              sx={{
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              ご本人について
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ mt: 10 }}>
            <MyText
              register={register("person_name", {
                ...VALIDATION_REQUIRED,
                ...VALIDATION_NAME,
              })}
              editMode={editMode}
              required
              label="お名前"
              placeholder="氏名を入力"
              error={formState.errors.person_name}
            ></MyText>
          </Grid>
          <Grid item xs={12} sx={{ mt: 10 }}>
            <MyPictureSelect
              editMode={editMode}
              label="アイコン"
              size="40%"
              url={temporaryUrl}
              onChange={selectFile}
            ></MyPictureSelect>
          </Grid>
          <Grid item xs={12} sx={{ mt: 10 }}>
            <MyDateSelect
              editMode={editMode}
              label="生年月日"
              required
              placeholder="生年月日を入力"
              value={birthDay}
              onChange={setBirthDay}
            ></MyDateSelect>
          </Grid>
          <Grid item xs={12} sx={{ mt: 10 }}>
            <MySelect
              register={register("gender", {
                ...VALIDATION_REQUIRED,
              })}
              control={control}
              editMode={editMode}
              label="性別"
              required
              placeholder="性別を選択"
              targetList={{
                1: "男",
                2: "女",
                U: "その他",
              }}
              error={formState.errors.gender}
            ></MySelect>
          </Grid>
          <Grid item xs={12} sx={{ mt: 10 }}>
            <MySelect
              register={register("blood_type", {
                ...VALIDATION_REQUIRED,
              })}
              control={control}
              editMode={editMode}
              required
              label="血液型"
              placeholder="血液型を選択"
              targetList={{
                A: "A型",
                B: "B型",
                AB: "AB型",
                O: "O型",
              }}
              error={formState.errors.blood_type}
            ></MySelect>
          </Grid>
          <Grid item xs={12} sx={{ mt: 10 }}>
            <MyText
              register={register("address", {
                ...VALIDATION_REQUIRED,
                ...VALIDATION_ADDRESS,
              })}
              editMode={editMode}
              label="住所"
              required
              placeholder="住所を入力"
              error={formState.errors.address}
            ></MyText>
          </Grid>
          <Grid item xs={12} sx={{ mt: 10 }}>
            <MyText
              register={register("tel_no", {
                ...VALIDATION_REQUIRED,
                ...VALIDATION_TEL_NO,
              })}
              editMode={editMode}
              required
              label="電話番号"
              placeholder="電話番号を入力"
              error={formState.errors.tel_no}
            ></MyText>
          </Grid>
          <Grid item xs={12} sx={{ mt: 10 }}>
            <MyText
              register={register("fax", {
                ...VALIDATION_TEL_NO,
              })}
              editMode={editMode}
              label="FAX"
              placeholder="FAX番号を入力"
              error={formState.errors.fax}
            ></MyText>
          </Grid>
          <Grid item xs={12} sx={{ mt: 10 }}>
            <MyText
              register={register("email", {
                ...VALIDATION_EMAIL,
              })}
              editMode={editMode}
              label="メールアドレス"
              placeholder="メールアドレスを入力"
              error={formState.errors.email}
            ></MyText>
          </Grid>
          <Grid item xs={12} sx={{ mt: 10 }}>
            <MyText
              register={register("profession", {
                maxLength: {
                  value: 10,
                  message: "10文字以内で入力してください",
                },
              })}
              editMode={editMode}
              label="職業"
              placeholder="職業を入力"
              error={formState.errors.profession}
            ></MyText>
          </Grid>
          <Grid item xs={12} sx={{ mt: 10 }}>
            <MyText
              register={register("permanent_domicile", {
                ...VALIDATION_REQUIRED,
                ...VALIDATION_ADDRESS,
              })}
              editMode={editMode}
              required
              label="本籍地"
              placeholder="本籍地を入力"
              error={formState.errors.permanent_domicile}
            ></MyText>
          </Grid>
          <Grid item xs={12} sx={{ mt: 10 }}>
            <MyText
              register={register("principal", {
                ...VALIDATION_NAME,
              })}
              editMode={editMode}
              label="筆頭者"
              placeholder="筆頭者を入力"
              error={formState.errors.principal}
            ></MyText>
          </Grid>
          <Grid item xs={12} sx={{ mt: 10 }}>
            <MySelect
              register={register("exist_partner", {
                ...VALIDATION_REQUIRED,
              })}
              control={control}
              editMode={editMode}
              required
              label="配偶者の有無"
              placeholder="配偶者の有無を選択"
              targetList={{
                1: "配偶者あり",
                2: "配偶者なし",
              }}
              error={formState.errors.exist_partner}
            ></MySelect>
          </Grid>
          <Grid item xs={12} sx={{ mt: 10 }}>
            <MyDateSelect
              editMode={editMode}
              label="配偶者生年月日"
              placeholder="配偶者の生年月日を入力"
              value={partnerBirthDay}
              onChange={setPartnerBirthDay}
            ></MyDateSelect>
          </Grid>
          <Grid item xs={12} sx={{ mt: 10 }}>
            <MySelect
              register={register("life_parent", {
                ...VALIDATION_REQUIRED,
              })}
              control={control}
              editMode={editMode}
              required
              label="親の生存"
              placeholder="親の生存状況を選択"
              targetList={{
                1: "生存している",
                2: "生存していない",
              }}
              error={formState.errors.life_parent}
            ></MySelect>
          </Grid>
          <Grid item xs={12} sx={{ mt: 10 }}>
            <MySelect
              register={register("life_children", {
                ...VALIDATION_REQUIRED,
              })}
              control={control}
              editMode={editMode}
              required
              label="子の生存"
              placeholder="子の生存状況を選択"
              targetList={{
                1: "生存している",
                2: "生存していない",
              }}
              error={formState.errors.life_children}
            ></MySelect>
          </Grid>
          <Grid item xs={12} sx={{ mt: 10 }}>
            <MySelect
              register={register("life_niece_nephew", {
                ...VALIDATION_REQUIRED,
              })}
              control={control}
              editMode={editMode}
              required
              label="姪甥の生存"
              placeholder="姪甥の生存状況を選択"
              targetList={{
                1: "生存している",
                2: "生存していない",
              }}
              error={formState.errors.life_niece_nephew}
            ></MySelect>
          </Grid>
          <Grid item xs={12} sx={{ mt: 10 }}>
            <MySelect
              register={register("housing_type", {
                ...VALIDATION_REQUIRED,
              })}
              control={control}
              editMode={editMode}
              required
              label="住宅の種別"
              placeholder="住宅の種別を選択"
              targetList={{
                1: "一戸建て（持ち家）",
                2: "一戸建て（賃貸）",
                3: "集合住宅（持ち家）",
                4: "集合住宅（賃貸）",
                5: "集合住宅（公団・公社）",
                6: "集合住宅（ホーム）",
              }}
              error={formState.errors.housing_type}
            ></MySelect>
          </Grid>
          <Grid item xs={12} sx={{ mt: 10 }}>
            <MyText
              register={register("housing_manager_name", {
                ...VALIDATION_CORPORATION_NAME,
              })}
              editMode={editMode}
              label="管理人（会社）名"
              placeholder="管理人または会社名を入力"
              error={formState.errors.housing_manager_name}
            ></MyText>
          </Grid>
          <Grid item xs={12} sx={{ mt: 10 }}>
            <MyText
              register={register("housing_manager_address", {
                ...VALIDATION_ADDRESS,
              })}
              editMode={editMode}
              label="管理人（会社）住所"
              placeholder="管理人または会社住所を入力"
              error={formState.errors.housing_manager_address}
            ></MyText>
          </Grid>
          <Grid item xs={12} sx={{ mt: 10 }}>
            <MyText
              register={register("housing_manager_tel_no", {
                ...VALIDATION_TEL_NO,
              })}
              editMode={editMode}
              label="管理人（会社）電話番号"
              placeholder="管理人または会社電話番号を入力"
              error={formState.errors.housing_manager_tel_no}
            ></MyText>
          </Grid>
          <Grid item xs={12} sx={{ mt: 10 }}>
            <MyText
              register={register("housing_manager_email", {
                ...VALIDATION_EMAIL,
              })}
              editMode={editMode}
              label="管理人（会社）メールアドレス"
              placeholder="管理人または会社メールアドレスを入力"
              error={formState.errors.housing_manager_email}
            ></MyText>
          </Grid>
          <Grid item xs={12} sx={{ mt: 10 }}>
            <MySelect
              register={register("use_safety_system", {
                ...VALIDATION_REQUIRED,
              })}
              control={control}
              editMode={editMode}
              required
              label="安否システムの有無"
              placeholder="安否システムの有無を選択"
              targetList={{
                1: "安否システムを現在利用している",
                2: "安否システムを現在利用していない",
              }}
              error={formState.errors.use_safety_system}
            ></MySelect>
          </Grid>
          <Grid item xs={12} sx={{ mt: 10 }}>
            <MySelect
              register={register("nursing_care_requirement_status", {
                ...VALIDATION_REQUIRED,
              })}
              control={control}
              editMode={editMode}
              required
              label="要介護認定状況"
              placeholder="要介護認定状況を選択"
              targetList={{
                "00": "自立",
                11: "要支援１",
                12: "要支援２",
                21: "要介護１",
                22: "要介護２",
                23: "要介護３",
                24: "要介護４",
                25: "要介護５",
              }}
              error={formState.errors.nursing_care_requirement_status}
            ></MySelect>
          </Grid>
          <Grid item xs={12} sx={{ mt: 10 }}>
            <MyText
              register={register("nursing_care_helper_name", {
                ...VALIDATION_NAME,
              })}
              editMode={editMode}
              label="介護保険担当ヘルパー名"
              placeholder="介護保険担当ヘルパー名を入力"
              error={formState.errors.nursing_care_helper_name}
            ></MyText>
          </Grid>
          <Grid item xs={12} sx={{ mt: 10 }}>
            <MyText
              register={register("nursing_care_helper_tel_no", {
                ...VALIDATION_TEL_NO,
              })}
              editMode={editMode}
              label="介護保険担当ヘルパー電話番号"
              placeholder="介護保険担当ヘルパー電話番号を入力"
              error={formState.errors.nursing_care_helper_tel_no}
            ></MyText>
          </Grid>
          <Grid item xs={12} sx={{ mt: 10 }}>
            <MyText
              register={register("nursing_care_manager_name", {
                ...VALIDATION_NAME,
              })}
              editMode={editMode}
              label="介護保険ケアマネージャー名"
              placeholder="介護保険ケアマネージャー名を入力"
              error={formState.errors.nursing_care_manager_name}
            ></MyText>
          </Grid>
          <Grid item xs={12} sx={{ mt: 10 }}>
            <MyText
              register={register("nursing_care_manager_tel_no", {
                ...VALIDATION_TEL_NO,
              })}
              editMode={editMode}
              label="介護保険ケアマネージャー電話番号"
              placeholder="介護保険ケアマネージャー電話番号を入力"
              error={formState.errors.nursing_care_manager_tel_no}
            ></MyText>
          </Grid>{" "}
          <Grid item xs={12} sx={{ mt: 10 }}>
            <MyText
              register={register("nursing_care_office_name", {
                ...VALIDATION_CORPORATION_NAME,
              })}
              editMode={editMode}
              label="介護保険事業所名"
              placeholder="介護保険事業名を入力"
              error={formState.errors.nursing_care_office_name}
            ></MyText>
          </Grid>
          <Grid item xs={12} sx={{ mt: 10 }}>
            <MyText
              register={register("nursing_care_office_tel_no", {
                ...VALIDATION_TEL_NO,
              })}
              editMode={editMode}
              label="介護保険事業所電話番号"
              placeholder="介護保険事業所電話番号"
              error={formState.errors.nursing_care_office_tel_no}
            ></MyText>
          </Grid>
          <Grid item xs={12} sx={{ mt: 10 }}>
            <MySelect
              register={register("exist_will", {})}
              control={control}
              editMode={editMode}
              label="遺言の有無"
              placeholder="遺言の有無を選択"
              targetList={{
                1: "遺言あり",
                2: "遺言なし",
              }}
              error={formState.errors.exist_will}
            ></MySelect>
          </Grid>
          <Grid item xs={12} sx={{ mt: 10 }}>
            <MyText
              register={register("will_storage_location", {
                ...VALIDATION_GENERAL_VALUE,
              })}
              editMode={editMode}
              label="遺言の保管場所"
              placeholder="遺言の保管場所を入力"
              error={formState.errors.will_storage_location}
            ></MyText>
          </Grid>
          <Grid item xs={12} sx={{ mt: 10 }}>
            <MyText
              register={register("hobby", {
                ...VALIDATION_GENERAL_VALUE,
              })}
              editMode={editMode}
              label="趣味"
              placeholder="趣味を入力"
              error={formState.errors.hobby}
            ></MyText>
          </Grid>
          <Grid item xs={12} sx={{ mt: 10 }}>
            <MyText
              register={register("specialty", {
                ...VALIDATION_GENERAL_VALUE,
              })}
              editMode={editMode}
              label="特技"
              placeholder="特技を入力"
              error={formState.errors.specialty}
            ></MyText>
          </Grid>
          <Grid item xs={12} sx={{ mt: 10 }}>
            <MyText
              register={register("favoite_food", {
                ...VALIDATION_GENERAL_VALUE,
              })}
              editMode={editMode}
              label="好きな食べ物"
              placeholder="好きな食べ物を入力"
              error={formState.errors.favoite_food}
            ></MyText>
          </Grid>
          <Grid item xs={12} sx={{ mt: 10 }}>
            <MyText
              register={register("dream", {
                ...VALIDATION_GENERAL_VALUE,
              })}
              editMode={editMode}
              label="夢・達成したいこと"
              placeholder="夢・達成したいことを入力"
              error={formState.errors.dream}
            ></MyText>
          </Grid>
          <Grid item xs={12} sx={{ mt: 10 }}>
            <MyText
              register={register("conception_of_life", {
                ...VALIDATION_GENERAL_VALUE,
              })}
              editMode={editMode}
              label="人生観・座右の銘"
              placeholder="人生観・座右の銘を入力"
              error={formState.errors.conception_of_life}
            ></MyText>
          </Grid>
        </Grid>
        <MyNoteActionFrame
          editMode={editMode}
          setEditMode={() => {
            if (editMode) {
              for (var key in currentData) {
                if (key === "thumbnail_path" || key === "thumbnail_url") {
                  continue;
                }
                setValue(key, currentData[key]);
              }

              setBirthDay(dayjs(currentData.birth_day));
              if (currentData.partner_birth_day) {
                setPartnerBirthDay(dayjs(currentData.partner_birth_day));
              } else {
                setPartnerBirthDay(null);
              }
              setTemporaryPath(currentData.thumbnail_path);
              setSourceTemporaryPath(currentData.thumbnail_path);
              setTemporaryUrl(currentData.thumbnail_url);
            }
            setEditMode(!editMode);
          }}
          onClickExec={handleSubmit(upsert)}
          onClickBackIcon={() =>
            navigate("/note", { state: { personId: "", note: true } })
          }
        ></MyNoteActionFrame>
      </Container>
    </AuthLayout>
  );
}

export default NoteMe;
