import "../../App.css";

import React from "react";

import { getFormatTelNo } from "../../func";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import CardMedia from "@mui/material/CardMedia";

// MyConstactListメイン
function MyContactList(props) {
  // 描画
  return (
    <React.Fragment>
      <List
        sx={{
          width: "100%",
          bgcolor: "background.paper",
          mt: -10,
        }}
      >
        {props.targetList.map((data, index) => {
          return (
            <React.Fragment key={index}>
              <ListItem alignItems="flex-start">
                <Grid container spacing={4}>
                  <Grid item xs={3}>
                    <CardMedia
                      component="img"
                      image={`${data.thumbnail_url}`}
                      sx={{ width: 80, ml: -8 }}
                      alt="Live from space album cover"
                      onClick={() => props.onClick(data.person_id)}
                    />
                  </Grid>
                  <Grid item xs={9}>
                    <Typography
                      sx={{ fontWeight: "bold" }}
                      onClick={() => props.onClick(data.person_id)}
                    >
                      {data.person_name}
                    </Typography>
                    <Typography sx={{ fontWeight: "bold" }}>
                      {getFormatTelNo(data.tel_no)}
                    </Typography>
                  </Grid>
                </Grid>
              </ListItem>
              <Divider sx={{ pt: 2 }} />
            </React.Fragment>
          );
        })}
      </List>
    </React.Fragment>
  );
}

export default MyContactList;
