import React from "react";
import { styled } from "@mui/material/styles";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CardMedia from "@mui/material/CardMedia";
import { SERVER_HOST } from "../constant";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

// MyPictureSelectメイン
function MyPictureSelect(props) {
  return (
    <React.Fragment>
      <Typography sx={{ fontWeight: "bold", mb: 2 }}>{props.label}</Typography>
      {(() => {
        if (props.editMode) {
          return (
            <Button
              component="label"
              role={undefined}
              variant="outlined"
              tabIndex={-1}
              fullWidth
              startIcon={<CameraAltIcon sx={{ mt: 2 }} />}
              onChange={(event) => props.onChange(event)}
              sx={{ mt: 4, mb: 2 }}
            >
              写真を選択
              <VisuallyHiddenInput type="file" accept="image/png, image/jpeg" />
            </Button>
          );
        } else {
        }
      })()}

      <React.Fragment>
        {(() => {
          if (props.url) {
            return (
              <CardMedia
                component="img"
                image={`${props.url}`}
                sx={{
                  maxWidth: props.size,
                  maxWeight: props.size,
                }}
                alt="loading..."
              />
            );
          }
        })()}
      </React.Fragment>
    </React.Fragment>
  );
}

export default MyPictureSelect;
