import React from "react";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

// MyTabPanelメイン
function MyTabPanel(props) {
  return (
    <React.Fragment>
      <Tabs
        value={props.selectTab}
        onChange={props.onChange}
        textColor="inherit"
        aria-label="basic tabs example"
        variant="fullWidth"
        sx={{ minHeight: "30px", height: "30px" }}
      >
        {props.targetList.map((data, index) => {
          return (
            <Tab
              label={data.label}
              sx={{
                backgroundColor: "#3F3F3F",
                color: "#FFFFFF",
                mt: -5,
              }}
              key={index}
              {...a11yProps(index)}
            />
          );
        })}
      </Tabs>
    </React.Fragment>
  );
}

export function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export default MyTabPanel;
